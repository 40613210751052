import React from 'react';
import Grid from '@mui/material/Grid';

import { Button, TextField,FormControl,InputLabel,Select,MenuItem } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Delete } from '@mui/icons-material';
import { isset } from '../../../Service/standard';
const FilterType = (props) => {
  const options = [];
  const columnsSubmenu = [];
  for(const row of props.header) {
    if (isset(row)) {
      options.push({
        value: row.field,
        label: row.headerName,
        type: row.myType,
      })
    }
  }
  const firstSelection = options[0];
  const optionsFilters = {
    'string': [
      <MenuItem value='includes' key='contiene'>Contiene </MenuItem>,
      <MenuItem value='=' key='uguale'>Uguale </MenuItem>,
      <MenuItem value='!=' key='diverso'>Diverso </MenuItem>,
    ],
    'date': [
      <MenuItem value='date=' key='uguale'>Uguale </MenuItem>,
      <MenuItem value='date!=' key='diverso'>Diverso </MenuItem>,
      <MenuItem value='>=' key='da'>Da </MenuItem>,
      <MenuItem value='<=' key='a'>A </MenuItem>,
    ],
    'datetime': [
      <MenuItem value='ore=' key='uguale'>Uguale </MenuItem>,
      <MenuItem value='ore!=' key='diverso'>Diverso </MenuItem>,
      <MenuItem value='ore>=' key='da'>Da </MenuItem>,
      <MenuItem value='ore<=' key='a'>A </MenuItem>,
    ],
    'number': [
      <MenuItem value='num=' key='uguale'>Uguale </MenuItem>,
      <MenuItem value='num!=' key='diverso'>Diverso </MenuItem>,
      <MenuItem value='num>=' key='da'>Da </MenuItem>,
      <MenuItem value='num<=' key='a'>A </MenuItem>,
    ],
    'real': [
      <MenuItem value='num=' key='uguale'>Uguale </MenuItem>,
      <MenuItem value='num!=' key='diverso'>Diverso </MenuItem>,
      <MenuItem value='num>=' key='da'>Da </MenuItem>,
      <MenuItem value='num<=' key='a'>A </MenuItem>,
    ]
  };
  const [propsTypeFiltersOptions, setPropsTypeFiltersOptions] = React.useState(isset(props.item) ? optionsFilters[options.filter((e) => { return e.value === Object.keys(props.item)[0]; })[0].type]: optionsFilters[firstSelection.type]);
  const [columnsValue, setColumnsValue] = React.useState(isset(props.item) ? Object.keys(props.item)[0] : firstSelection.value);
  const [filterValue, setFilterValue] = React.useState(isset(props.item) ? props.item[Object.keys(props.item)[0]][1] : optionsFilters[firstSelection.type][0].props.value);
  const [defaultInputValue, setDefaultInputValue] = React.useState(isset(props.item) ? props.item[Object.keys(props.item)[0]][0] : '');
  const [disabled, setDisabled] = React.useState((isset(props.item) && props.item[Object.keys(props.item)[0]][0].length > 0) ? true : false);
  for (const val of options) {
    columnsSubmenu.push(
        <MenuItem value={val.value} key={val.value}>{val.label} </MenuItem>
    );
  }

  const changeColumnHandler = (event) => {
    setColumnsValue(event.target.value);
    const filtersChange = options.filter((e) => { return e.value === event.target.value; })[0].type;
    setFilterValue(optionsFilters[filtersChange][0].props.value);
    setPropsTypeFiltersOptions(optionsFilters[filtersChange])
  }
  const changeFilterHanler = (event) => {
    setFilterValue(event.target.value);
  }

  const deleteFilterHandler = () => {
    const filter = {
      type: filterValue,
      column: columnsValue,
    }
    props.removeFilter(filter);
  }
  React.useEffect(() => {
    setPropsTypeFiltersOptions(isset(props.item) ? optionsFilters[options.filter((e) => { return e.value === Object.keys(props.item)[0]; })[0].type]: optionsFilters[firstSelection.type]);
    setColumnsValue(isset(props.item) ? Object.keys(props.item)[0] : firstSelection.value);
    setFilterValue(isset(props.item) ? props.item[Object.keys(props.item)[0]][1] : optionsFilters[firstSelection.type][0].props.value);
    setDefaultInputValue(isset(props.item) ? props.item[Object.keys(props.item)[0]][0] : '');
    setDisabled((isset(props.item) && props.item[Object.keys(props.item)[0]][0].length > 0) ? true : false);
  }, [props.item]);

  const filterHandler = (event) => {
    setDefaultInputValue(event.target.value);
    const filter = {
      type: filterValue,
      column: columnsValue,
      value: event.target.value,
    }
    if(event.target.value.length > 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    props.filterData(filter);
  }
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={2} md={1}  sx={{ marginTop: 2 }}>
          <Button onClick={deleteFilterHandler}>
            <Delete />
          </Button>
        </Grid>

        <Grid item xs={5} md={3} sx={{ marginTop: 2,textAlign:'center' }} key={'columns_grid'}>
            <FormControl

                size="small"
                disabled={disabled}
                sx={{borderBottom: 1}} 
                className="popoverFilter" 
            >
                <InputLabel id={'columns_helper_label'}>{"COLONNE"}</InputLabel>
                <Select
                    labelId={'columns_helper_label'}
                    value={columnsValue}
                    onChange={changeColumnHandler}
                >
                    {columnsSubmenu}
                </Select>
            </FormControl>
        </Grid>

        <Grid item xs={5} md={2} sx={{ marginTop: 2,textAlign:'center' }} key={'filterType_grid'}>
            <FormControl

                size="small"
                disabled={disabled}
                sx={{borderBottom: 1}}
                className="popoverFilter" 
            >
                <InputLabel id={'filterType_helper_label'}>{"FILTRI"}</InputLabel>
                <Select
                  labelId={'filterType_helper_label'}
                  value={filterValue}
                  onChange={changeFilterHanler}
                >
                  {propsTypeFiltersOptions}
                </Select>

            </FormControl>
        </Grid>
        <Grid item xs={12} md={5} sx={{ marginTop: 2,textAlign:'center' }}>
         <TextField className="popoverFilter"  sx={{borderBottom: 1}} onChange={filterHandler} value={defaultInputValue}  defaultValue={defaultInputValue}></TextField>
        </Grid>

      </Grid>
    </React.Fragment>
  )

}

export default FilterType;