import { Grid, Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { postRest } from "../../Service/callRest";
import React from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { getCookie } from "../../Service/standard";
import PType from "../includes/PType/ptype";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';


const Auth = (props) => {

    const [options, setOptions] = useState([]);

    const { _obj } = useParams();
    const { _id } = useParams();
    const navigate = useNavigate();


    const save = async () => {
        props.pageLoader(true);
        const obj = [];
        for (const iterator of options) {
            let id = iterator.props.children[2].props.props.name;
            obj.push({ id: id, value: document.getElementById(id).value });
        }

        const params = {
            obj: _obj,
            id: _id,
            data: obj,
            session: getCookie('code')
        };

        const res = await postRest(props,   'controller/auth', params);
        props.pageLoader(false);
        navigate('/single/'+_obj+'/' + _id);
    }


    const getAuth = async () => {
        props.pageLoader(true);
        const params = {
            obj: _obj,
            id: _id,
            session: getCookie('code')
        };

        const res = await postRest(props,   'controller/auth', params);
        const tmp = [];

        for (const iterator of res) {

            tmp.push(
                <React.Fragment key={iterator.id + '_grid_label'}>
                    <Grid item xs={3} sx={{ marginTop: 2 }} />
                    <Grid item xs={3} sx={{ marginTop: 2 }}>
                        <Box component={'h6'} >{iterator.name}</Box>
                    </Grid>
                        <PType 
                            props={{
                                type: 'toggle',
                                name: iterator.id, 
                                value: iterator.enable,
                                columns: 3
                            }}
                            isDisabled={false}
                        />
                    <Grid item xs={3} sx={{ marginTop: 2 }} />
                </React.Fragment>

            )
        }
        setOptions(tmp);
        props.pageLoader(false);
    }
    const backLink = () => {
        navigate( -1 );
    }
    useEffect(() => { getAuth() }, []);


    return (
        <React.Fragment>
            <Grid container>
                <Box component={'div'} className='cursor-pointer mb-3'>
                    <Button onClick={backLink} color="primary"> <ArrowCircleLeftIcon/>&nbsp; Torna Indietro </Button> 
                </Box>
                <Grid item xs={12}>
                    <Box component={'div'} className='card my-4'>
                        <Box component={'div'} className='card-header p-0 position-relative mt-n4 mx-3 z-index-2'>
                            <Box component={'div'} className='bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3'>
                                <Box component={'h6'} className='text-white text-capitalize ps-3 text-center fs-6'>Autorizzazioni</Box>
                            </Box>
                        </Box>
                        <Box component={'div'} className='row box m-4 text-center'>
                            {options}
                            <Button
                                onClick={save}
                                className={"btn btn-success w-100"}
                                sx={{marginTop: 5}}
                            >Salva</Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>

    );
}


export default Auth;