import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { IsNumeric, isset } from "../../../Service/standard";
import { Icon } from '@mui/material';
import * as icons from '@mui/icons-material';
import { postRest } from "../../../Service/callRest";
import Swal from 'sweetalert2';

const OptionButton = (props) => {
  const navigate = useNavigate();
  const { _id } = useParams();
  const { _obj } = useParams();
  const { _thisObj } = useParams();
  const { _thisId } = useParams();
  const propss = props.props;
  const types = {

    hidden: {
      label: '',
      type: 'success',
      path: '',
      method: 'path',
      display: 'none'
    },

    AddSingle: {
      label: 'Aggiungi',
      icon: 'Add',
      type: 'btn-success',
      path: 'edit/{{obj}}/0',
      method: 'path',
      extraPath: '/{{thisObj}}/{{thisId}}',
      display: ''
    },

    Edit: {
      label: 'Modifica',
      icon: 'Edit',
      type: 'btn-warning',
      path: 'edit/{{obj}}/{{id}}',
      method: 'path',
      display: ''
    },


    AddAuth: {
      label: 'Modifica',
      icon: 'Edit',
      type: 'btn-warning',
      path: 'Auth',
      extraPath: '{{thisObj}}/{{thisId}}',
      method: 'path',
      display: ''
    },

    RegolarizzaEC: {
      label: 'Regolarizza',
      icon: 'Euro',
      type: 'btn-purple',
      path: 'ManageEC/{{id}}',
      method: 'path',
      // extraPath: '{{thisObj}}/{{thisId}}',
      display: ''
    },

    CalendarPrenotazioni: {
      label: 'Calendario',
      icon: 'CalendarMonth',
      type: 'btn-purple',
      path: 'calendarPrenotazioni/{{id}}',
      method: 'path',
      // extraPath: '{{thisObj}}/{{thisId}}',
      display: ''
    },
    
    ValidatePreEmployee: {
      label: 'Valida',
      icon: 'DoneOutline',
      type: 'btn-success',
      method: 'validatePreEmployee',
      display: ''
    },
    Delete: {
      label: 'Elimina',
      icon: 'Cancel',
      type: 'btn-danger',
      method: 'discard',
      display: ''
    },
    DiscardPreEmployee: {
      label: 'Scarta',
      icon: 'Cancel',
      type: 'btn-danger',
      method: 'discardPreEmployee',
      display: ''
    },
  };

  const [property] = useState(isset(types[propss.buttons]) ? types[propss.buttons] : types['hidden']);

  const path = () => {
    if (property.path.includes('{{')) {
      for (const iterator of property.path.split('{{')) {
        if (iterator.includes('}}')) {
          property.path = property.path.replace('{{' + iterator.split('}}')[0] + '}}', propss[iterator.split('}}')[0]]);
        }
      }
    }
    if (isset(property.extraPath)) {
      for (const iterator of property.extraPath.split('{{')) {
        if (iterator.includes('}}')) {
          if (isset(props[iterator.split('}}')[0]])) {
            property.path += '/' + props[iterator.split('}}')[0]];
          }
        }
      }
    }
    // navigate('/');
    navigate('/' + property.path);
  }

  const validatePreEmployee = async () => {
    props.loading(true);
    const params = {
      id: _id,
      mode: 'check'
    };
    const res = await postRest(props, 'controller/validaPreEmployee', params);
    if (res.status !== false) {
      props.loading(false);
      Swal.fire({
        title: 'Sei sicuro?',
        html: res.message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Valida',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          props.loading(true);
          const params2 = {
            id: _id,
            mode: 'save',
            type: res.type
          };
          const resp = await postRest(props, 'controller/validaPreEmployee', params2);
          if (IsNumeric(resp.message)) {
            props.loading(false);
            navigate("/single/Employees/" + resp.message);
          } else {
            props.loading(false);
            Swal.fire({
              title: 'Impossibile Salvare',
              html: resp.message,
              icon: 'warning',
            });
          }
        }
      });
    } else {
      props.loading(false);
      Swal.fire({
        title: 'Impossibile Salvare',
        html: res.message,
        icon: 'warning',
      });
    }
  }

  const discard = async () => {
    Swal.fire({
      title: 'Sei sicuro?',
      html: 'Sei sicuro di voler eliminare i dati presenti?',
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: 'light-blue',
      confirmButtonText: 'Elimina',
      cancelButtonText: 'No'
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        props.loading(true);
        const params2 = {
          id: _id,
          obj: _obj
        };
        const resp = await postRest(props, 'controller/delete', params2);
        if (resp.status === true) {
          props.loading(false);
          if(isset(_thisObj)){
            navigate("/single/"+_thisObj+"/"+_thisId);
          } else {
            navigate("/tableList/"+_obj);
          }
        } else {
          props.loading(false);
          Swal.fire({
            title: 'Impossibile Eliminare',
            html: resp.message,
            icon: 'warning',
          });
        }
      }
    });
  }
  const discardPreEmployee = async () => {
    Swal.fire({
      title: 'Sei sicuro?',
      html: 'Sei sicuro di voler scartare la pre anagrafica',
      icon: 'danger',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: 'light-blue',
      confirmButtonText: 'Scarta',
      cancelButtonText: 'No'
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        props.loading(true);
        const params2 = {
          id: _id,
          mode: 'discard'
        };
        const resp = await postRest(props, 'controller/validaPreEmployee', params2);
        if (resp.status === true) {
          props.loading(false);
          navigate("/tableList/PreEmployees");
        } else {
          props.loading(false);
          Swal.fire({
            title: 'Impossibile Salvare',
            html: resp.message,
            icon: 'warning',
          });
        }
      }
    });
  }

  return (

    <Button
      onClick={eval(property.method)}
      className={"btn btn-sm rounded-pill " + property.type}
      size="small"
      sx={{ display: property.display, marginLeft: '5px' }}
    >
      <Icon component={icons[property.icon]} />
      {' ' + property.label}
    </Button>

  )
}

export default OptionButton;