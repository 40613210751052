import { ThemeProvider, createTheme } from '@mui/material/styles';
import './App.css';
import Login from './components/Login/login';
import TableList from './components/TableList/tableList';
import './bootstrap.css';
import React, { useState } from 'react';
import {LightMode, DarkMode} from '@mui/icons-material';
import {getCookie, isset, setCookie} from './Service/standard';
import useMediaQuery from '@mui/material/useMediaQuery';
import {BrowserRouter,Route,Routes} from 'react-router-dom'
import DashBoard from './components/DashBoard/dashboard';
import Main from './components/Main/main';
import Single from './components/Single/single';
// import Iban from './components/custom/Iban';
import MyAttachments from './components/custom/MyAttachments';
import Payrolls from './components/custom/Payrolls';
import Loader from './components/includes/Loader/loader';
import Auth from './components/custom/Auth';
import Booking from './components/custom/Booking';
import Signup from './components/Signup/signup';
import ManageEC from './components/custom/ManageEC';
import RecuperaPassword from './components/RecuperaPassword/recuperaPassword';
import Settings from './components/Settings/settings';
import Calendar from './components/Calendar/calendar';
import CalendarPrenotazioni from './components/CalendarPrenotazioni/calendarprenotazioni';
import CalendarPrenotazioniPersonali from './components/CalendarPrenotazioniPersonali/calendarprenotazionipersonali';
import OAuth from './components/OAuth/OAuth';
import WorkSession from './components/WorkSession/workSession';
import ObiettiviBO from './components/ObiettiviBO/ObiettiviBO';

const dark = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    icon: <LightMode color="primary" sx={{cursor: 'pointer'}} />,
    class: 'dark-version',
    white: "#fff"
  },
});


const light = createTheme({
  palette: {
    primary: {
      main: '#191919',
    },
    icon: <DarkMode color="primary" sx={{cursor: 'pointer'}} />,
    white: "#fff",
    class: 'light-version'
  },
});


const App = () => {

  const selectedTheme = useMediaQuery('(prefers-color-scheme: dark)') ? dark : light;
  const cookieTheme = (getCookie('theme').length > 0 ? (JSON.parse(getCookie('theme')).backgroud === 'light' ? light : dark) : '');
  

  const[theme, setTheme] = useState(getCookie('theme').length > 0 ? cookieTheme : selectedTheme);
  const[colorTheme, setColorTheme] = useState(getCookie('theme').length > 0 ? JSON.parse(getCookie('theme')).color : 'blue-type');
  const [isLoggedIn, setIsLoggedIn] = useState(getCookie('code').length === 50 ? true : false);
  const [pageLoader, setPageLoader] = useState(false);


  const changeThemeHandler = (event) => {
    let color = getCookie('theme').length > 0 ? JSON.parse(getCookie('theme')).color  : colorTheme;
    
    if (!isset(event)) {
      if(theme === light){
        let newTheme = {
          backgroud:'dark',
          color:color
        }
        newTheme = JSON.stringify(newTheme);
        setCookie('theme', newTheme, 365);
        setTheme(dark);
        setBodyTheme(theme);
      }else{
        let newTheme = {
          backgroud:'light',
          color:color
        }
        newTheme = JSON.stringify(newTheme);
        setCookie('theme', newTheme, 365);
        setTheme(light);
        setBodyTheme(theme);
      }
    } else{
      setColorTheme(event.target.id);

      let newTheme = {
        backgroud:getCookie('theme').length > 0 ? JSON.parse(getCookie('theme')).backgroud  : 'light',
        color:event.target.id
      }
      newTheme = JSON.stringify(newTheme);
      setCookie('theme', newTheme, 365);
      setBodyTheme(theme);
    }
  }
  

  const setBodyTheme = (theme) => {
    document.getElementById('body').classList.remove('blue-type');
    document.getElementById('body').classList.remove('red-type');
    document.getElementById('body').classList.remove('green-type');
    document.getElementById('body').classList.remove('pink-type');
    document.getElementById('body').classList.add(getCookie('theme').length > 0 ? JSON.parse(getCookie('theme')).color  : colorTheme);

    if (theme === light) {
      document.getElementById('body').classList.remove('dark-version');
      document.getElementById('body').classList.add('light-version');
    } else {
      document.getElementById('body').classList.remove('light-version');
      document.getElementById('body').classList.add('dark-version');
    }
  }
   
  const loginStatusHandler = (status) => {
    if(status === true){
      if(getCookie('code').length !== 50){
        status = false;
      }
    }
    if(status === false){
      setCookie('code', '', 365);
    }
    setIsLoggedIn(status);
  };

  const PageLoaderHandler = (status) => {
    setPageLoader(status);
  };

  // document.addEventListener('keydown', (event) => {
  //   if(event.keyCode === 123 && process.env.NODE_ENV != 'development'){
  //     event.preventDefault();
  //   }
  // })

  return (
    <React.Fragment>
      <Loader open={pageLoader}/>
      {!isLoggedIn &&
      <ThemeProvider theme={light}>
        {setBodyTheme(light)}
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Login onLogin={loginStatusHandler} pageLoader={PageLoaderHandler} />} />
            <Route path='/OAuth/:_token' element={<OAuth onLogin={loginStatusHandler} pageLoader={PageLoaderHandler} />} />
            <Route path='/:_lv1' element={<Login onLogin={loginStatusHandler} pageLoader={PageLoaderHandler} />} />
            <Route path='/:_lv1/:_lv2' element={<Login onLogin={loginStatusHandler} pageLoader={PageLoaderHandler} />} />
            <Route path='/:_lv1/:_lv2/:_lv3' element={<Login onLogin={loginStatusHandler} pageLoader={PageLoaderHandler} />} />
            <Route path='/:_lv1/:_lv2/:_lv3/:_lv4' element={<Login onLogin={loginStatusHandler} pageLoader={PageLoaderHandler} />} />
            <Route path='/:_lv1/:_lv2/:_lv3/:_lv4/:_lv5' element={<Login onLogin={loginStatusHandler} pageLoader={PageLoaderHandler} />} />
            <Route path='/signup' element={<Signup onLogin={loginStatusHandler} pageLoader={PageLoaderHandler} />} />
            <Route path='/passwordRecovery' element={<RecuperaPassword onLogin={loginStatusHandler} pageLoader={PageLoaderHandler} />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
      }
      {isLoggedIn && 
      <ThemeProvider theme={theme}>
        {setBodyTheme(theme)}
        <BrowserRouter>
          <Main onLogout={loginStatusHandler} onChangeTheme={changeThemeHandler} theme={theme}>        
            <Routes>
              <Route exact  path='/' element={<DashBoard name="DashBoard" onLogout={loginStatusHandler} pageLoader={PageLoaderHandler} theme={theme}/>} />
              <Route path='/areapersonale/payrolls' element={<Payrolls onLogout={loginStatusHandler} pageLoader={PageLoaderHandler} />} />
              <Route path='/areapersonale/myAttachments' element={<MyAttachments onLogout={loginStatusHandler} pageLoader={PageLoaderHandler} />} />
              <Route path='/areapersonale/booking' element={<Booking onLogout={loginStatusHandler} pageLoader={PageLoaderHandler} />} />
              <Route path='/settings' element={<Settings onLogout={loginStatusHandler} pageLoader={PageLoaderHandler} theme={theme} />} />
              <Route path='/Auth/:_obj/:_id' element={<Auth onLogout={loginStatusHandler} pageLoader={PageLoaderHandler} />} />
              <Route path='/ManageEC/:_id' element={<ManageEC onLogout={loginStatusHandler} pageLoader={PageLoaderHandler} />} />

              <Route path='/tableList/:_obj' element={<TableList onLogout={loginStatusHandler} pageLoader={PageLoaderHandler}/>} />
              <Route path='/tableList/:_obj/:_filters' element={<TableList onLogout={loginStatusHandler} pageLoader={PageLoaderHandler}/>} />
              <Route path='/tableList/:_obj/:_qExtra/:_where' element={<TableList onLogout={loginStatusHandler} pageLoader={PageLoaderHandler}/>} />

              <Route path='/:_mode/:_obj/:_id' element={<Single onLogout={loginStatusHandler} pageLoader={PageLoaderHandler} />} />
              <Route path='/:_mode/:_obj/:_id/:_thisObj/:_thisId' element={<Single onLogout={loginStatusHandler} pageLoader={PageLoaderHandler} />} />

              {/* <Route path='/:_mode/Iban/:_id' element={<Iban onLogout={loginStatusHandler} pageLoader={PageLoaderHandler} />} /> */}
              {/* <Route path='/:_mode/Iban/:_id/:_thisObj/:_thisId' element={<Iban onLogout={loginStatusHandler} pageLoader={PageLoaderHandler} />} /> */}
              <Route path='/calendar' element={<Calendar onLogout={loginStatusHandler} pageLoader={PageLoaderHandler} />} />
              <Route path='/calendarPrenotazioni/:_project' element={<CalendarPrenotazioni onLogout={loginStatusHandler} pageLoader={PageLoaderHandler} />} />
              <Route path='/calendarPrenotazioni' element={<CalendarPrenotazioni onLogout={loginStatusHandler} pageLoader={PageLoaderHandler} />} />
              <Route path='/calendarioPrenotazioni' element={<CalendarPrenotazioniPersonali onLogout={loginStatusHandler} pageLoader={PageLoaderHandler} />} />
              <Route path='/obiettiviBO/:_service/:_product' element={<ObiettiviBO onLogout={loginStatusHandler} pageLoader={PageLoaderHandler} theme={theme} />} />
              <Route path='/workSession' element={<WorkSession onLogout={loginStatusHandler} pageLoader={PageLoaderHandler} />} />
              
            </Routes>
          </Main>
        </BrowserRouter>
      </ThemeProvider>
      }
    </React.Fragment>
  );
}

export default App;
