import { postRest } from "../../Service/callRest";
import { useNavigate, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PType from '../includes/PType/ptype';
import { IsNumeric, isset } from "../../Service/standard";
import { Button } from "@mui/material";
import BasicTable from "../includes/BasicTable/basitable";
import Swal from "sweetalert2";
import GenericAlerts from "../includes/GenericAlerts/GenericAlerts";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';



const ManageEC = (props) => {

    const { _id } = useParams();
    const [ec, setEc] = useState([]);
    const [suggest, setSuggest] = useState([]); //item delle tabelle e hide status
    const [suggestions, setSuggestions] = useState([]); 
    const navigate = useNavigate();
    const [hideCustomize, setHideCustomize] = useState(false);
    const [list, setList] = useState([]);
    const [selected, setSelected] = useState([]);
    const [fatts, setFatts] = useState([]);
    const [type, setType] = useState(0);
    const [placeholder, setPlaceholder] = useState("");
    const [importo, setImporto] = useState(0);
    const [totale, setTotale] = useState(0);
    const [numRows, setNumRows] = useState(0);

    let suggestData = [];
    const params = {
        id: _id
    };

    let idFat = "";
    let selectedArr = [];
    const [hideAddNew, setHideAddNew] = useState(false);

    const save = async() => {
        const params = {
            fatts: fatts,
            id: _id
        };

        const res = await postRest(props, 'controller/saveEc', params);
        if(isset(res.success) && res.success == true){
            navigate('/single/' + 'EstrattoConto' + '/' + _id);
        } else {
            Swal.fire({
                title: 'Impossibile Salvare',
                text: res.error,
                icon: 'warning',
            });
        }
    }
    const onChangeSelect = async (event) => {
        const value = event.target.value;

        suggestData = await getEc();

        if (IsNumeric(value)) {
            for (const key in suggestData) {
                suggestData[key]['hide'] = true;
            }
            createSuggestions(true);
            if (value != type) {
                setType(value);
                setNumRows(1);
                let page = "getFattureEc";
                let type = "";
                

                switch (value) {
                    case 1:
                        type = "attive";
                        setPlaceholder("Seleziona Fatture Attive");
                        break;

                    case 2:
                        type = "passive";
                        setPlaceholder("Seleziona Fatture Passive");
                        break;

                    default:
                        page = "";
                        setPlaceholder("");
                        break;
                }

                const params = {
                    cod_ec: _id,
                    type: type
                };
                setList(await postRest(props, 'controller/' + page, params));
            } else {
                setType(value);
            }
        } else {
            for (const key in suggestData) {
                suggestData[key]['hide'] = false;
            }
            setType(value);
            setHideAddNew(true);
            setNumRows(0);
            setList([]);
            setPlaceholder("");
        }
        createSuggestions(true);
    }

    const createRow = () => {
        const tmp = [];
        for (let i = 0; i < numRows; i++) {
            tmp[i] = [
                <PType
                    key={'selected_' + i}
                    props={{
                        type: 'autocomplete',
                        name: 'selected_' + i,
                        label: placeholder,
                        value: (isset(fatts[i])) ? fatts[i].idFat : "",
                        options: list,
                        columns: 6,
                        onChangeValue: onChangeImport
                    }}
                    isDisabled={false}
                />,

                <PType
                    key={'importo_' + i}
                    props={{
                        type: 'text',
                        name: 'importo_' + i,
                        label: 'Importo (€)',
                        value: (isset(fatts[i])) ? fatts[i].importo : "",
                        columns: 5
                    }}
                    onChangeEvent={onChangeImport}
                    isDisabled={false}
                />,
                <Grid item xs={12} md={1} sx={{ marginTop: 2 }} key={i}>
                    <Button
                        className={"btn btn-danger"}
                        id={i}
                        onClick={removeRow}
                        >Elimina
                    </Button>
                </Grid>
            ];

        }

        setSelected(tmp);
        selectedArr = tmp;
    }

    const removeRow = (event) => {
        let id = event.currentTarget.id;
        selectedArr = selectedArr.filter((item, i) => { return i != id });
        setFatts(fatts.filter((item, i) => { return i != id }));
        setNumRows(numRows - 1);
        setSelected(selectedArr);
        setCalcolo(fatts.filter((item, i) => { return i != id }));

    }

    const onChangeImport = (event, value = '') => {
        if(value !== ''){
            idFat = value;
        }

        let idRow = event.currentTarget.id.replace('_auto', '').replace('selected_', '').replace('importo_', '');
        let importoFat = document.getElementById('importo_' + idRow).value;
        
        if(!IsNumeric(importoFat)){
            let fatt = list.filter((item) => { return item.value == idFat });
            if(fatt.length > 0){
                importoFat = fatt[0].importo;
            }else{
                importoFat = 0;
            }
        }
        
        if(!isset(importoFat)){
            importoFat = 0;
        } else{
            importoFat = Number(importoFat);
        }
        
        const tmp = fatts;
        

        tmp[idRow] = { idFat: idFat, importo: importoFat };
        setFatts(tmp);
        setCalcolo(tmp);
    }
    const setCalcolo = (tmp) => {
        let tot = 0;
        for (const iterator of tmp) {
            tot += iterator.importo;
            if (tot > totale) {
                tot = totale
            }
        }
        setImporto(
            totale - tot
        );
        
        if((totale - tot) === 0){
            setHideAddNew(true);
        }else{
            setHideAddNew(false);
        }
    }

    const getEc = async () => {
        props.pageLoader(true);
        const res = await postRest(props, 'controller/getEstrattoconto', params);
        let tmpFatt = {};
        
        for (const tmpsuggest in res.suggest) {
            let count = 0;
            tmpFatt[tmpsuggest] = {};
            tmpFatt[tmpsuggest]['hide'] = false;
            tmpFatt[tmpsuggest]['data'] = [];
            let index = 0;
            for (const iterator of res.suggest[tmpsuggest]) {
    
                for (const key in iterator) {
                    let object = [];
                    for (const word of String(iterator[key]).split('&&&')) {
                        object.push(
                            word,
                            <Box component={'br'} key={suggest + key + index + 1}/>
                        );
                        index ++;
                    }
                    iterator[key] = object;
                }
    
                iterator['items'] = [iterator['numero'], iterator['ricevente'], iterator['data_emissione'], iterator['saldo_fattura'],
                
                <React.Fragment>
                    <PType 
                        props={{
                            type: 'hidden',
                            name: tmpsuggest + '_fatts_' + count,
                            value: iterator['id'].filter((item) => { return typeof item == 'string' }),
                            columns: 6
                        }}
                    
                    />
                    <PType 
                        props={{
                            type: 'toggle',
                            name: tmpsuggest + '_toggle_fatts_' + count,
                            value: 0,
                            columns: 12
                        }}
                        custom
                        returnOnChangeValue={onChangeSuggestHandler}
                    />
                </React.Fragment>
            
            ];
                tmpFatt[tmpsuggest]['data'].push(iterator);
                count++;
            }
        }
        suggestData = tmpFatt;
        setSuggest(tmpFatt);
        setImporto(Math.abs(res.importo));
        setTotale(Math.abs(res.importo));
        setEc([
            <PType
                key={'societa'}
                props={{
                    type: 'text',
                    name: 'societa',
                    label: 'Società',
                    value: res.nameSocieta,
                    columns: 6
                }}
                isDisabled={true}
            />,
            <PType
            key={'data'}
            props={{
                type: 'text',
                name: 'data',
                label: 'Data',
                value: res.dataValuta,
                columns: 6
            }}
            isDisabled={true}
            />,
            <PType
                key={'importo'}
                props={{
                    type: 'text',
                    name: 'importo',
                    label: 'Importo (€)',
                    value: res.importo,
                    columns: 6
                }}
                isDisabled={true}
            />,
            <PType
                key={'descrizione'}
                props={{
                    type: 'textarea',
                    name: 'descrizione',
                    label: 'Descrizione Movimento',
                    value: res.descrizione,
                    columns: 6
                }}
                isDisabled={true}
            />
        ]);
        props.pageLoader(false);
        return suggestData;
    }

    const onChangeSuggestHandler = (event) => {
        const id = event.currentTarget.id;


        const idFat = document.getElementById(id.replace('toggle_', '')).value;
        let tmp = [];
        document.getElementById(id).value = (document.getElementById(id).value == "1") ? "0" : "1";
        if(document.getElementById(id).value == 1){
            tmp[0] = { idFat: idFat };
            const type = id.split('_')[0];

            for (const key in suggestData) {
                if(key != type){
                    suggestData[key]['hide'] = true;
                }
            }
            createSuggestions(true);


            for (let index = 0; index < suggestData[type]['data'].length; index++) {
                if(index != id.replace(type + '_toggle_fatts_', '')){
                    document.getElementById(type + '_toggle_fatts_' + index).parentElement.className = document.getElementById(type + '_toggle_fatts_' + index).parentElement.className.replace('Mui-checked', '');
                    document.getElementById(type + '_toggle_fatts_' + index).value = "0";
                }
            }
        }else{
            tmp = [];
            for (const key in suggestData) {
                suggestData[key]['hide'] = false;
            }
            createSuggestions(true);
        }
        setFatts(tmp);
        if(tmp.length == 0){
            setHideCustomize(false);
        }else{
            setHideCustomize(true);
        }
    }

    const createSuggestions = (set = false) => {
        const tmp = [];
        const tmpSuggest = (set === true) ? suggestData : suggest;
        for (const key in tmpSuggest) {
            if(tmpSuggest[key]['hide'] === false){
                tmp.push(
                    <Grid item xs={12} key={'grid_' + key}>
                        <Box component={'div'} className='card my-4'>
                            <Box component={'div'} className='card-header p-0 position-relative mt-n4 mx-3 z-index-2'>
                                <Box component={'div'} className='bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3'>
                                    <Box component={'h6'} className='text-white text-capitalize ps-3 text-center fs-6'>Regolarizzazione Consigliata su Fatture {key[0].toUpperCase() + key.slice(1)}</Box>
                                </Box>
                            </Box>
                            {
                                tmpSuggest[key]['data'].length == 0 &&
                                <Box component={'div'} className='m-4 box text-center text-bold fs-5'>
                                    Non sono presenti Suggerimenti. Procedere con la Regolarizzazione Personalizzata
                                </Box>
                            }
                            {
                                tmpSuggest[key]['data'].length > 0 &&
                                <Box component={'div'} className='m-4 row box'>
                                    <Box component={'div'} className='table-responsive p-0'>
                                        <BasicTable
                                            headers={[ 
                                                {key: 'numero', headerName: 'Numero Fattura'},
                                                {key: 'ricevente', headerName: 'Società Ricevente'},
                                                {key: 'data_emissione', headerName: 'Data Emissione'},
                                                {key: 'saldo_fattura', headerName: 'Importo (€)'},
                                                {key: 'selected', headerName: 'Seleziona'},
                                            ]}
                                            items={tmpSuggest[key]['data']}
                                        />
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Grid>
                )
            }
        }
        setSuggestions(tmp);
    }

    React.useEffect(() => {
        getEc();
    }, [_id]);

    
    React.useEffect(() => {
        createRow();
    }, [list, numRows]);
    
    React.useEffect(() => {
        createSuggestions();
    }, [suggest]);

    const backLink = () => {
        navigate( -1 );
    }
    return (
        <React.Fragment>
            <Grid container>
                <Box component={'div'} className='cursor-pointer mb-3'>
                    <Button onClick={backLink} color="primary"> <ArrowCircleLeftIcon/>&nbsp; Torna Indietro </Button> 
                </Box>
                <Grid item xs={12}>
                    <Box component={'div'} className='card my-4'>
                        <Box component={'div'} className='card-header p-0 position-relative mt-n4 mx-3 z-index-2'>
                            <Box component={'div'} className='bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3'>
                                <Box component={'h6'} className='text-white text-capitalize ps-3 text-center fs-6'>Movimento da Regolarizzare</Box>
                            </Box>
                        </Box>
                        <Box component={'div'} className='m-4 row box'>
                            {ec}
                        </Box>
                    </Box>
                </Grid>
                {suggestions}
                {
                    !hideCustomize &&
                    <Grid item xs={12}>
                        <Box component={'div'} className='card my-4'>
                            <Box component={'div'} className='card-header p-0 position-relative mt-n4 mx-3 z-index-2'>
                                <Box component={'div'} className='bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3'>
                                    <Box component={'h6'} className='text-white text-capitalize ps-3 text-center fs-6'>Regolarizzazione Personalizzata</Box>
                                </Box>
                            </Box>
                            <Box component={'div'} className='m-4 row box'>
                                <GenericAlerts
                                        category={'Informazioni Generali'}
                                        type={'info'}
                                        msg={[

                                            '- Separare i decimali con il separatore punto (.)',
                                            '',
                                            '- In caso di totale importi insufficiente, la differenza tra l\'importo del movimento e il totale degli importi dichiarati sarà aggiunto all\'ultima regolarizzazione',
                                            'ES: totale mov €100, totale importi €90. Valore ultimo importo = importo dichiarato + (100 - 90)',
                                            '',
                                            '- In caso di totale importi maggiore, la differenza tra il totale degli importi dichiarati e l\'importo del movimento sarà sottratto all\'ultima regolarizzazione',
                                            'ES: totale mov €90, totale importi €100. Valore ultimo importo = importo dichiarato - (100 - 90)',
                                        ]}
                                        closing
                                 />
                                <Box component={'h6'} className='text-white text-capitalize ps-3 text-center fs-6'>Importo Rimanente: € {importo.toFixed(2)}</Box>
                                <PType
                                    key={'select_type'}
                                    props={{
                                        type: 'select',
                                        name: 'type',
                                        label: 'Seleziona Tipologia Regolarizzazione',
                                        options: [
                                            { value: 1, label: 'Fatture Attive' },
                                            { value: 2, label: 'Fatture Passive' },
                                            { value: 3, label: 'Conti' },
                                        ],
                                        onChange: onChangeSelect,
                                        value: "",
                                        columns: 12
                                    }}
                                    isDisabled={false}
                                />
                                {selected}
                            </Box>
                            <Box component={'div'} className='m-4 text-center'>
                                {
                                    // hideSuggest &&
                                    !hideAddNew &&
                                    <Button
                                        className={"btn btn-purple w-25"}
                                        onClick={() => {setNumRows(numRows + 1)}}
                                        sx={{marginBottom: 10}}
                                        >Aggiungi Nuovo
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </Grid>
                }
                <Button
                    className={"btn btn-success w-100"}
                    onClick={save}
                    >Salva
                </Button>
            </Grid>
        </React.Fragment>
    )
}

export default ManageEC;