import React from 'react';
import Box from '@mui/material/Box';
import { AddCircle } from '@mui/icons-material';
import { Button, Popover, Typography } from '@mui/material';
import {  useParams } from 'react-router-dom';
import FilterType from './filterType';
import { getCookie, isset, setCookie } from '../../../Service/standard';

const SwitchFilter = (id, dataToFilter) => {
  switch (id[Object.keys(id)[0]][1]) {
    case 'includes':
      return dataToFilter.filter((item) =>  {
        let string = (item[Object.keys(id)[0]] !== null) ? item[Object.keys(id)[0]] : '';
        return string.toLowerCase().includes(id[Object.keys(id)[0]][0].toLowerCase());
      })
    case '=':
      return dataToFilter.filter((item) => item[Object.keys(id)[0]] == id[Object.keys(id)[0]][0]);
    case '!=':
      return dataToFilter.filter((item) => item[Object.keys(id)[0]] != id[Object.keys(id)[0]][0]);
    case 'num=':
      return dataToFilter.filter((item) => Number(item[Object.keys(id)[0]]) == Number(id[Object.keys(id)[0]][0]));
    case 'num!=':
      return dataToFilter.filter((item) => Number(item[Object.keys(id)[0]]) != Number(id[Object.keys(id)[0]][0]));
    case 'num>=':
      return dataToFilter.filter((item) => Number(item[Object.keys(id)[0]]) >= Number(id[Object.keys(id)[0]][0]));
    case 'num<=':
      return dataToFilter.filter((item) => Number(item[Object.keys(id)[0]]) <= Number(id[Object.keys(id)[0]][0]));
    case '>=':
      let datemin = id[Object.keys(id)[0]][0].split('/');
      datemin = datemin[2]+'-'+datemin[1]+'-'+datemin[0];
      return dataToFilter.filter((item) => new Date(item[Object.keys(id)[0]]).getTime() >= new Date(datemin).getTime());
    case '<=': 
      let datemax = id[Object.keys(id)[0]][0].split('/');
      datemax = datemax[2]+'-'+datemax[1]+'-'+datemax[0];
      return dataToFilter.filter((item) => new Date(item[Object.keys(id)[0]]).getTime() <= new Date(datemax).getTime());
    case 'date=':
      let date = id[Object.keys(id)[0]][0].split('/');
      date = date[2]+'-'+date[1]+'-'+date[0];
      return dataToFilter.filter((item) => new Date(item[Object.keys(id)[0]]).getTime() == new Date(date).getTime());
    case 'date!=':
      let datediv = id[Object.keys(id)[0]][0].split('/');
      datediv = datediv[2]+'-'+datediv[1]+'-'+datediv[0];
      return dataToFilter.filter((item) => new Date(item[Object.keys(id)[0]]).getTime() != new Date(datediv).getTime());
    case 'ore>=':
      let datetimemin = id[Object.keys(id)[0]][0].split(' ')[0].split('/');
      datetimemin = datetimemin[2]+'-'+datetimemin[1]+'-'+datetimemin[0]+' '+id[Object.keys(id)[0]][0].split(' ')[1];
      return dataToFilter.filter((item) => new Date(item[Object.keys(id)[0]]).getTime() >= new Date(datetimemin).getTime());
    case 'ore<=': 
      let datetimemax = id[Object.keys(id)[0]][0].split(' ')[0].split('/');
      datetimemax = datetimemax[2]+'-'+datetimemax[1]+'-'+datetimemax[0]+' '+id[Object.keys(id)[0]][0].split(' ')[1];
      return dataToFilter.filter((item) => new Date(item[Object.keys(id)[0]]).getTime() <= new Date(datetimemax).getTime());
    case 'ore=':
      let datetime = id[Object.keys(id)[0]][0].split(' ')[0].split('/');
      datetime = datetime[2]+'-'+datetime[1]+'-'+datetime[0]+' '+id[Object.keys(id)[0]][0].split(' ')[1];
      return dataToFilter.filter((item) => new Date(item[Object.keys(id)[0]]).getTime() == new Date(datetime).getTime());
    case 'ore!=': 
      let datetimediv = id[Object.keys(id)[0]][0].split(' ')[0].split('/');
      datetimediv = datetimediv[2]+'-'+datetimediv[1]+'-'+datetimediv[0]+' '+id[Object.keys(id)[0]][0].split(' ')[1];
      return dataToFilter.filter((item) => new Date(item[Object.keys(id)[0]]).getTime() != new Date(datetimediv).getTime());
    default:
      return dataToFilter
  }
}

const Filters = (props) => {
  const [open, setOpen] = React.useState(props.popoverOpen);
  const [filters, setFilters] = React.useState([]);
  const modal = open ? 'simple-Popover' : undefined;
  const [width, setWidth] = React.useState(window.innerWidth);
  const [widthPopover, setWidthPopover] = React.useState(850);
  const [left, setleft] = React.useState(550);

  const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
  }
  React.useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 870;
  React.useEffect(() => {
    if (isMobile) {
      setWidthPopover(400);
      setleft(150);
    }else{
      setWidthPopover(850);
      setleft(550);
    }
}, [isMobile]);
  
  React.useEffect(() => {
    setOpen(props.popoverOpen);
    if (filters.length > 0) {
      
      setFiltersOBJ(filters);   
    } else {
      setFiltersOBJ([1]);  
    }
  }, [props.popoverOpen]); 
  const handleClose = () => {
    setOpen(false);
    props.onClose();
  }; 


  const FilterData = (e) => {
    const column = e.column;
    const value = e.value;
    const type = e.type;
    const tmpFilters = filters;
    const filter = {
      [column]: [
        value,
        type
      ],
    }
    
    let i = 0;


     for (const iterator of tmpFilters) {
      if (isset(iterator[column])) {
        if (iterator[column][1] === type) {
          tmpFilters.splice(i,1);
        }
      }
      i++;
    }
    if (value.length > 0) {
      tmpFilters.push(filter);
    }


    setFilters(tmpFilters);
    let cookieFilter = [];
    if (getCookie('filters') !== "") {
      cookieFilter = JSON.parse(getCookie('filters'));
    } 
    let cookieFilters  = {
      'obj' : props.obj,
      'filters' : tmpFilters
    };
    let currObj = true;
    let tmpCookieFilters = [];
    if (cookieFilter.length > 0) {
      for (const iterator of cookieFilter) {
          
        if (iterator.obj !== props.obj) {
          tmpCookieFilters.push(iterator);
        } else {
          currObj = false;
          tmpCookieFilters.push(cookieFilters);
        }
      }
    }
    if (currObj === true) {
      tmpCookieFilters.push(cookieFilters);
    }
    
    setCookie('filters',JSON.stringify(tmpCookieFilters),1);
    let dataToFilter = props.data;
    tmpFilters.forEach((id) => {
      dataToFilter = SwitchFilter(id, dataToFilter);
    });
    props.countFilters(tmpFilters.length);
    props.filterData(dataToFilter);
    if (filtersOBJ.length === 0) {
      setFiltersOBJ([1]);
    } 

  }



  const removeFilter = (e) => {
    const column = e.column;
    const type = e.type;
    const tmpFilters = filters;
    
    let i = 0;
    for (const iterator of tmpFilters) {
      if (isset(iterator[column])) {
        if (iterator[column][1] === type) {
          tmpFilters.splice(i,1);
        }
      }
      i++;
    }
    setFilters(tmpFilters);
    let cookieFilter = JSON.parse(getCookie('filters'));
    let cookieFilters  = {
      'obj' : props.obj,
      'filters' : tmpFilters
    };
    let tmpCookieFilters = [];
    for (const iterator of cookieFilter) {
        
      if (iterator.obj !== props.obj) {
        tmpCookieFilters.push(iterator);
      } else {
        tmpCookieFilters.push(cookieFilters);
      }
    }

    setCookie('filters',JSON.stringify(tmpCookieFilters),1);
    let dataToFilter = props.data;
    tmpFilters.forEach((id) => {
      dataToFilter = SwitchFilter(id, dataToFilter);
    });
    props.countFilters(tmpFilters.length);
    props.filterData(dataToFilter);
    if (filtersOBJ.length === 0) {
      setFiltersOBJ([1]);
    } else {
      setFiltersOBJ([...tmpFilters]);
    }
  }

  const doFilter = () => {  
    let dataToFilter = props.data;
    filters.forEach((id) => {
      dataToFilter = SwitchFilter(id, dataToFilter);
    });
    props.countFilters(filters.length);
    props.filterData(dataToFilter);
  }

  const [filtersOBJ, setFiltersOBJ] = React.useState([1]); 
  
  const addFilter = () => {
    
    setFiltersOBJ([...filtersOBJ, filtersOBJ.length +1]);
    
  }
  
  
  React.useEffect(() => {
    setFiltersOBJ([1]);
    if (getCookie('filters') !== "") {
      let cookieFilter = JSON.parse(getCookie('filters'));
      for (const iterator of cookieFilter) {
        if(iterator.obj === "StdTable"){
          cookieFilter[iterator] = {};
          iterator.obj = "";
        } 
          
        if (iterator.obj === props.obj) {
          
          if (filters.length === 0 && iterator.filters.length > 0) {
            setFilters(iterator.filters);
          }
        }
      }
    }
    doFilter();
  }, [props.obj,filters,props.data]);

  const [tmpFiltersType, setTmpFiltersType] = React.useState([]);
  const getFilters = () => {
    let tmpLetFilters = [];
    let i = 0;
    for (const iterator of filtersOBJ) {
      if (isNaN(iterator)){

        tmpLetFilters.push(<FilterType 
          header={props.header} 
          myKey={i} 
          key={i} 
          item={iterator}
          removeFilter={removeFilter} 
          filterData={FilterData} 
        />)
      } else {

        tmpLetFilters.push(<FilterType 
          header={props.header} 
          myKey={i} 
          key={i} 
          removeFilter={removeFilter} 
          filterData={FilterData} 
        />)
      }
      i ++;
    }
    setTmpFiltersType(tmpLetFilters);
  }

  React.useEffect(() => {
    setTmpFiltersType([]);   
    getFilters();
  }, [filtersOBJ]);
  return (
    <React.Fragment>
      <Popover
      
        id={modal}
        open={open}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 250, left: left }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography 
        sx={{ p: 2,width:widthPopover+'px' }}
        className="popoverFilters"
        >
          {tmpFiltersType}
          <Box component={'div'} sx={{ marginTop:'10px'}}>

            <Button onClick={addFilter}>
              <AddCircle></AddCircle>
            </Button>
          </Box>
        </Typography>
      </Popover>
    </React.Fragment>
  )

}

export default Filters;