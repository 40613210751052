import { downloadAttach, postRest } from "./callRest";

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const isset = (ref) => typeof ref !== 'undefined';

export const dateToString = (date, format = 'en', notime = false) => {
  date = new Date(date);
  let result = '';


  if(format === 'en'){
    result = date.getFullYear() + '-' + String((date.getMonth() + 1)).padStart(2,'0') + '-' + String(date.getDate()).padStart(2,'0');
    if(result === '1970-01-01'){
      result = '';
    }
  }

  if(format === 'it'){
    result = String(date.getDate()).padStart(2,'0') + '/' + String((date.getMonth() + 1)).padStart(2,'0') + '/'+ date.getFullYear();
    if(result === '01/01/1970'){
      result = '';
    }
  }

  if(!notime && (date.getHours() > 0 || date.getMinutes() > 0)){
    result += ' ' + String(date.getHours()).padStart(2,'0') + ':'+ String(date.getMinutes()).padStart(2,'0');
    if(date.getSeconds() > 0){
      result += ':'+ String(date.getSeconds()).padStart(2,'0');
    }
  }

  return result;

};


export const IsNumeric = (val) => {
  return Number(parseFloat(val)) == val;
}


export const download = async (props, id) => {
  const params = {
      id: id
  };

  const file = await downloadAttach(props,   'controller/download', params);
}

export const getMese = (month) => {
  let text = '';
  switch (month) {
    case 1:
        text = 'Gennaio';
        break;
    case 2:
        text = 'Febbraio';
        break;
    case 3:
        text = 'Marzo';
        break;
    case 4:
        text = 'Aprile';
        break; 
    case 5:
        text = 'Maggio';
        break;
    case 6:
        text = 'Giugno';
        break;
    case 7:
        text = 'Luglio';
        break;
    case 8:
        text = 'Agosto';
        break;  
    case 9:
        text = 'Settembre';
        break;
    case 10:
        text = 'Ottobre';
        break;
    case 11:
        text = 'Novembre';
        break;
    case 12:
        text = 'Dicembre';
        break;     
    case 13:
        text = 'Tredicesima';
        break;     
    case 14:
        text = 'Quattordicesima';
        break;     
  }
  return text;
}