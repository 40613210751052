import back from './../../../medias/Comverta-Logo-Menu.svg';
import Box from '@mui/material/Box';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import * as React from 'react';
import { postRest } from '../../../Service/callRest';
import { isset } from '../../../Service/standard';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@mui/material';
import * as icons from '@mui/icons-material';


const SideBar = (props) => {
    const navigate = useNavigate();
    const [openItems, setOpenItems] = React.useState([]);
    const [activeMenu, setActiveMenu] = React.useState([]);

    const handleActive = (item, tmp = []) => {
        if(!openItems.includes(item.key)){
            tmp.push(item.key);
            if(isset(item.parent)){
                for (const iterator of item.parent) {
                    tmp.push(iterator);
                }
            }
        }else if(openItems.includes(item.parent)){
            tmp.push(item.key);
        }
        setActiveMenu(tmp);
    }


    const handleToggle = (item) => {
        // setActiveMenu(item);
        handleActive(item);
        if (openItems.includes(item.key)) {
            setOpenItems(openItems.filter((i) => i !== item.key));
        } else {
            setOpenItems([...openItems, item.key]);
        }
    };

    const [menu, setMenu] = React.useState('');
    const menuHandler = async () => {
        const response = await postRest(props,   `controller/menu`);
        setMenu(response);
    };

    const createMenu = (components, padding = 2) => {
        const arr = [];
        if (isset(components)) {
            for (const element of components) {

                if (isset(element.components)) {
                    arr.push(
                        <React.Fragment key={element.key+'_fragment'} >

                        <ListItemButton sx={{ pl: padding, wordBreak: 'break-word' }} className={activeMenu.includes(element.key) ? 'nav-item active icon-sidebar' : 'nav-item icon-sidebar'} key={element.key} onClick={() => handleToggle(element)}>
                            <ListItemIcon sx={{paddingLeft: 2}}>
                                <Icon component={icons[element.icon]} />
                            </ListItemIcon>
                            <ListItemText primary={element.name} />
                            {openItems.includes(element.key) ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openItems.includes(element.key)} key={element.key+'_collapse'}  timeout="auto" unmountOnExit>
                            <List component="div" disablePadding >
                                {createMenu(element.components, padding + 2)}
                            </List>
                        </Collapse>
                        </React.Fragment>
                    );
                } else {

                    arr.push(
                        <ListItemButton sx={{ pl: padding, wordBreak: 'break-word' }} className={activeMenu.includes(element.key) ? 'nav-item active' : 'nav-item'} key={element.key}  onClick={() => { 
                            handleActive(element); 
                            props.showMenu();
                            isset(element.method) ? props[element.method](element.value) : 
                            (
                                String(element.path).includes('https://') ? 
                                window.open(
                                    element.path,
                                    '_blank' 
                                ) 
                              : navigate(element.path)
                            )
                            }}>
                            <ListItemIcon className='icon-sidebar' sx={{paddingLeft: 2}}>
                                <Icon component={icons[element.icon]} />
                            </ListItemIcon>
                             <ListItemText primary={element.name}/>
                        </ListItemButton>
                    );
                }
            }
        }
        return arr;
    }


    return (
        <Box component={'aside'} className='sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark' onLoad={menuHandler}>
            <Box component={'div'} className='sidenav-header'>
                <Box component={'div'} className='navbar-brand text-center cursor-pointer' onClick={() => {setActiveMenu([]);setOpenItems([]);props.showMenu();navigate('/')}}>
                    <img src={`${back}`} className="navbar-brand-img h-100" alt="main_logo" />
                </Box>
            </Box>
            <Box component={'div'} className='text-center font-weight-bold text-white' sx={{ paddingBottom: '10px', marginTop: '-10px' }}>{menu.name}</Box>
            <hr className="horizontal light mt-0 mb-2"></hr>
            <Box component={'div'} className='collapse navbar-collapse w-auto h-auto'>
                <List sx={{ color: 'white', padding: '10px' }} className='navbar-nav'>
                    {createMenu(menu.components)}
                </List>
            </Box>
        </Box>
    )
}

export default SideBar;