import { Box, Button, Grid, Select, InputLabel, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Typography, FormControl } from "@mui/material";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { getMese } from "../../Service/standard";
import { dateToString } from "../../Service/standard";
import { isset } from "../../Service/standard";
import { postRest } from "../../Service/callRest";
import FileDownload from "@mui/icons-material/FileDownload";
import styled from "@emotion/styled";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';



const ObiettiviBO = (props) => {

    const [currentDateMonth] = useState(dateToString(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-01'));
    const [currentYearMonth, setCurrentYearMonth] = useState(getMese(new Date(currentDateMonth).getMonth() + 1) + ' ' + new Date(currentDateMonth).getFullYear());
    const [calendar, setTable] = useState([]);
    const [header, setHeader] = useState([]);
    const [services, setServices] = useState([]);
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();
    const { _product } = useParams();
    const { _service } = useParams();
    const [serviceValue, setServiceValue] = useState("ALL");
    const [productValue, setProductValue] = useState("ALL");
    const [progress, setProgress] = useState(0);
    

    useEffect(() => {
        setCurrentYearMonth(getMese(new Date(currentDateMonth).getMonth() + 1) + ' ' + new Date(currentDateMonth).getFullYear());
        getServices();
        getProducts();
        getTarget();
        if (isset(_product)) {
            setServiceValue(_service);
            setProductValue(_product);
            createReport();
        }
    }, [currentDateMonth,_product,productValue, serviceValue, _service]);

    const backLink = () => {
        navigate( -1 );
    }

    const createReport = async () => {
        props.pageLoader(true);
        let rows = [];
        
        const res = await postRest(props, 'controller/obiettiviBO', { service: _service, product: _product });
        let head = []
        head.push(
            <TableCell className="text-center" key={'product'}>Prodotto</TableCell>,
            <TableCell className="text-center" key={'type'}>Tipo Campagna</TableCell>,
            <TableCell className="text-center" key={'campaign'}>Campagna</TableCell>,
            <TableCell className="text-center" key={'sito'}>Sito</TableCell>,
            <TableCell className="text-center" key={'sito'}>Liste</TableCell>,
            <TableCell className="text-center" key={'totale'}>Tot.</TableCell>,
            <TableCell className="text-center" key={'perc_totale'}>Rap. Perc. Tot.</TableCell>,
            <TableCell className="text-center" key={'yesterday'}>Tot. Ieri</TableCell>,
            <TableCell className="text-center" key={'perc_totale_yesterday'}>Rap. Perc. Tot. Ieri</TableCell>,
        );
        
        let index_prodotto = 0;
        for (const prodotto in res.single) {
            rows.push(
                <TableRow>
                    <TableCell sx={{borderLeft: "none", borderRight: "none", borderBottom: "none", borderTop: "black"}} prodotto={prodotto}>{prodotto}</TableCell>
                    <TableCell sx={{border: "none"}} ></TableCell>
                    <TableCell sx={{border: "none"}} ></TableCell>
                    <TableCell sx={{border: "none"}} ></TableCell>
                    <TableCell sx={{border: "none"}} ></TableCell>
                    <TableCell sx={{border: "none"}} ></TableCell>
                    <TableCell sx={{border: "none"}} ></TableCell>
                    <TableCell sx={{border: "none"}} ></TableCell>
                    <TableCell sx={{border: "none"}} ></TableCell>
                </TableRow>
            );

            let index_type_campagna = 0;
            for (const type_campaign in res["single"][prodotto]){
                rows.push(
                    <TableRow>
                        <TableCell sx={{border: "none"}} ></TableCell>
                        <TableCell sx={{border: "none"}}  key={type_campaign + index_type_campagna}>{type_campaign}</TableCell>
                        <TableCell sx={{border: "none"}} ></TableCell>
                        <TableCell sx={{border: "none"}} ></TableCell>
                        <TableCell sx={{border: "none"}} ></TableCell>
                        <TableCell sx={{border: "none"}} ></TableCell>
                        <TableCell sx={{border: "none"}} ></TableCell>
                        <TableCell sx={{border: "none"}} ></TableCell>
                        <TableCell sx={{border: "none"}} ></TableCell>
                    </TableRow>
                );

                let index_campagna = 0;
                for (const campagna in res["single"][prodotto][type_campaign]) {
                    rows.push(
                        <TableRow>
                            <TableCell sx={{border: "none"}} ></TableCell>
                            <TableCell sx={{border: "none"}}></TableCell>
                            <TableCell sx={{border: "none"}} key={campagna + index_campagna}>{campagna}</TableCell>
                            <TableCell sx={{border: "none"}} ></TableCell>
                            <TableCell sx={{border: "none"}} ></TableCell>
                            <TableCell sx={{border: "none"}} ></TableCell>
                            <TableCell sx={{border: "none"}} ></TableCell>
                            <TableCell sx={{border: "none"}} ></TableCell>
                            <TableCell sx={{border: "none"}} ></TableCell>
                        </TableRow>
                    );

                    
                    let index_sito = 0;
                    for (const sito in res["single"][prodotto][type_campaign][campagna]) {

                        rows.push(
                            <TableRow>
                                <TableCell sx={{border: "none"}}></TableCell>
                                <TableCell sx={{border: "none"}}></TableCell>
                                <TableCell sx={{border: "none"}}></TableCell>
                                <TableCell key={sito + index_sito} sx={{border: "none"}}>{sito}</TableCell>
                                <TableCell sx={{border: "none"}}></TableCell>
                                <TableCell sx={{border: "none"}}></TableCell>
                                <TableCell sx={{border: "none"}}></TableCell>
                                <TableCell sx={{border: "none"}}></TableCell>
                                <TableCell sx={{border: "none"}}></TableCell>
                            </TableRow>
                        );

                        let index_lista = 0;
                        for(const lista in res["single"][prodotto][type_campaign][campagna][sito]["lists"]){
                            if(lista != 'rimanenti'){
                                rows.push(
                                    <TableRow>
                                        <TableCell sx={{border: "none"}}></TableCell>
                                        <TableCell sx={{border: "none"}}></TableCell>
                                        <TableCell sx={{border: "none"}}></TableCell>
                                        <TableCell sx={{border: "none"}}></TableCell>
                                        <TableCell key={lista + index_lista} sx={{border: "none"}}>{lista}</TableCell>
                                        <TableCell key={"tot" + sito + index_sito} sx={{border: "none"}}><Box component={'div'} className={(res["single"][prodotto][type_campaign][campagna][sito]['lists'][lista].totale - res["single"][prodotto][type_campaign][campagna][sito]['lists'][lista].expected < 0) ? "red-div" : "green-div"}>{res["single"][prodotto][type_campaign][campagna][sito]['lists'][lista].totale + " / "+ res["single"][prodotto][type_campaign][campagna][sito]['lists'][lista].expected}</Box></TableCell>
                                        
                                        <TableCell sx={{border: "none"}}><Box component={'div'} className={(res["single"][prodotto][type_campaign][campagna][sito]['lists'][lista].perc_totale < 100) ? "red-div" : "green-div"}>{res["single"][prodotto][type_campaign][campagna][sito]['lists'][lista].perc_totale + " %"}</Box></TableCell>
                                        <TableCell key={"tot_ieri" + sito + index_sito} sx={{border: "none"}}><Box component={'div'} className={(res["single"][prodotto][type_campaign][campagna][sito]['lists'][lista].totale_ieri - res["single"][prodotto][type_campaign][campagna][sito]['lists'][lista].expected_ieri < 0) ? "red-div" : "green-div"}>{res["single"][prodotto][type_campaign][campagna][sito]['lists'][lista].totale_ieri + " / "+ res["single"][prodotto][type_campaign][campagna][sito]['lists'][lista].expected_ieri}</Box></TableCell>
                                        <TableCell sx={{border: "none"}}><Box component={'div'} className={(res["single"][prodotto][type_campaign][campagna][sito]['lists'][lista].perc_totale_ieri < 100) ? "red-div" : "green-div"}>{res["single"][prodotto][type_campaign][campagna][sito]['lists'][lista].perc_totale_ieri + " %"}</Box></TableCell>
                                    </TableRow>
                                );
                                rows.push(
                                    <TableRow>
                                        <TableCell className="cell-separator"></TableCell>
                                        <TableCell className="cell-separator"></TableCell>
                                        <TableCell className="cell-separator"></TableCell>
                                        <TableCell className="cell-separator"></TableCell>
                                        <TableCell className="cell-separator"><hr width="100%"/></TableCell>
                                        <TableCell className="cell-separator"><hr width="100%"/></TableCell>
                                        <TableCell className="cell-separator"><hr width="100%"/></TableCell>
                                        <TableCell className="cell-separator"><hr width="100%"/></TableCell>
                                        <TableCell className="cell-separator"><hr width="100%"/></TableCell>
                                    </TableRow>
                                );
                            }
                        }

                        rows.push(
                            <TableRow>
                                <TableCell sx={{border: "none"}}></TableCell>
                                <TableCell sx={{border: "none"}}></TableCell>
                                <TableCell sx={{border: "none"}}></TableCell>
                                <TableCell sx={{border: "none"}}></TableCell>
                                <TableCell key={"Rimanenti"} sx={{border: "none"}}>{"Rimanenti"}</TableCell>
                                <TableCell key={"tot" + sito + index_sito} sx={{border: "none"}}><Box component={'div'} className={(res["single"][prodotto][type_campaign][campagna][sito]['lists']['rimanenti'].totale - res["single"][prodotto][type_campaign][campagna][sito]['lists']['rimanenti'].expected < 0) ? "red-div" : "green-div"}>{res["single"][prodotto][type_campaign][campagna][sito]['lists']['rimanenti'].totale + " / "+ res["single"][prodotto][type_campaign][campagna][sito]['lists']['rimanenti'].expected}</Box></TableCell>
                                
                                <TableCell sx={{border: "none"}}><Box component={'div'} className={(res["single"][prodotto][type_campaign][campagna][sito]['lists']['rimanenti'].perc_totale < 100) ? "red-div" : "green-div"}>{res["single"][prodotto][type_campaign][campagna][sito]['lists']['rimanenti'].perc_totale + " %"}</Box></TableCell>
                                <TableCell key={"tot_ieri" + sito + index_sito} sx={{border: "none"}}><Box component={'div'} className={(res["single"][prodotto][type_campaign][campagna][sito]['lists']['rimanenti'].totale_ieri - res["single"][prodotto][type_campaign][campagna][sito]['lists']['rimanenti'].expected_ieri < 0) ? "red-div" : "green-div"}>{res["single"][prodotto][type_campaign][campagna][sito]['lists']['rimanenti'].totale_ieri + " / "+ res["single"][prodotto][type_campaign][campagna][sito]['lists']['rimanenti'].expected_ieri}</Box></TableCell>
                                <TableCell sx={{border: "none"}}><Box component={'div'} className={(res["single"][prodotto][type_campaign][campagna][sito]['lists']['rimanenti'].perc_totale_ieri < 100) ? "red-div" : "green-div"}>{res["single"][prodotto][type_campaign][campagna][sito]['lists']['rimanenti'].perc_totale_ieri + " %"}</Box></TableCell>
                            </TableRow>
                        );

                        //separatore sito
                        if(index_sito <  Object.keys(res["single"][prodotto][type_campaign][campagna]).length - 1){
                            rows.push(
                                <TableRow>
                                    <TableCell className="cell-separator"></TableCell>
                                    <TableCell className="cell-separator"></TableCell>
                                    <TableCell className="cell-separator"></TableCell>
                                    <TableCell className="cell-separator"><hr width="100%"/></TableCell>
                                    <TableCell className="cell-separator"><hr width="100%"/></TableCell>
                                    <TableCell className="cell-separator"><hr width="100%"/></TableCell>
                                    <TableCell className="cell-separator"><hr width="100%"/></TableCell>
                                    <TableCell className="cell-separator"><hr width="100%"/></TableCell>
                                    <TableCell className="cell-separator"><hr width="100%"/></TableCell>
                                </TableRow>
                            );
                        }
                        index_sito++;
                    }
                    //separatore campagna
                    if(index_campagna <  Object.keys(res["single"][prodotto][type_campaign]).length - 1){
                        rows.push(
                            <TableRow>
                                <TableCell className="cell-separator"></TableCell>
                                <TableCell className="cell-separator"></TableCell>
                                <TableCell className="cell-separator"><hr width="100%"/></TableCell>
                                <TableCell className="cell-separator"><hr width="100%"/></TableCell>
                                <TableCell className="cell-separator"><hr width="100%"/></TableCell>
                                <TableCell className="cell-separator"><hr width="100%"/></TableCell>
                                <TableCell className="cell-separator"><hr width="100%"/></TableCell>
                                <TableCell className="cell-separator"><hr width="100%"/></TableCell>
                                <TableCell className="cell-separator"><hr width="100%"/></TableCell>
                            </TableRow>
                        );
                    }
                    index_campagna++;
                }

                //separatore tipologia campagna
                rows.push(
                    <TableRow>
                        <TableCell className="cell-separator"></TableCell>
                        <TableCell className="cell-separator"><hr width="100%"/>TOTALE</TableCell>
                        <TableCell className="cell-separator"><hr width="100%"/>TOTALE</TableCell>
                        <TableCell className="cell-separator"><hr width="100%"/>TOTALE</TableCell>
                        <TableCell className="cell-separator"><hr width="100%"/>TOTALE</TableCell>
                        <TableCell className="cell-separator test"><hr width="100%"/><Box component={'div'} className={(res["all"][prodotto][type_campaign]["totale"] - res["all"][prodotto][type_campaign]["expected"] < 0) ? "red-div" : "green-div"}>{res["all"][prodotto][type_campaign]["totale"] + " / "+ res["all"][prodotto][type_campaign]["expected"]}</Box></TableCell>
                        <TableCell className="cell-separator"><hr width="100%"/><Box component={'div'} className={(res["all"][prodotto][type_campaign].perc_totale < 100) ? "red-div" : "green-div"}>{res["all"][prodotto][type_campaign].perc_totale + " %"}</Box></TableCell>
                        <TableCell className="cell-separator"><hr width="100%"/><Box component={'div'} className={(res["all"][prodotto][type_campaign]["totale_ieri"] - res["all"][prodotto][type_campaign]["expected_ieri"] < 0) ? "red-div" : "green-div"}>{res["all"][prodotto][type_campaign]["totale_ieri"] + " / "+ res["all"][prodotto][type_campaign]["expected_ieri"]}</Box></TableCell>
                        <TableCell className="cell-separator"><hr width="100%"/><Box component={'div'} className={(res["all"][prodotto][type_campaign].perc_totale_ieri < 100) ? "red-div" : "green-div"}>{res["all"][prodotto][type_campaign].perc_totale_ieri + " %"}</Box></TableCell>
                    </TableRow>
                );
                if(
                    (index_type_campagna < (Object.keys(res["single"][prodotto]).length - 1))
                ){
                    rows.push(
                        <TableRow>
                            <TableCell className="cell-separator"></TableCell>
                            <TableCell className="cell-separator"><hr className="hr-separator" width="100%"/></TableCell>
                            <TableCell className="cell-separator"><hr className="hr-separator"width="100%"/></TableCell>
                            <TableCell className="cell-separator"><hr className="hr-separator"width="100%"/></TableCell>
                            <TableCell className="cell-separator"><hr className="hr-separator"width="100%"/></TableCell>
                            <TableCell className="cell-separator"><hr className="hr-separator"width="100%"/></TableCell>
                            <TableCell className="cell-separator"><hr className="hr-separator"width="100%"/></TableCell>
                            <TableCell className="cell-separator"><hr className="hr-separator"width="100%"/></TableCell>
                            <TableCell className="cell-separator"><hr className="hr-separator"width="100%"/></TableCell>
                        </TableRow>
                    );
                }
                index_type_campagna++;

            }

            let tot = {
                totale: 0,
                expected: 0,
                perc_totale: 0,
                totale_ieri: 0,
                expected_ieri: 0,
                perc_totale_ieri: 0
            };

            for (const type_campaign in res["single"][prodotto]) {
                tot.totale += parseInt(res["all"][prodotto][type_campaign]["totale"]);
                tot.expected += parseInt(res["all"][prodotto][type_campaign]["expected"]);
                tot.totale_ieri += parseInt(res["all"][prodotto][type_campaign]["totale_ieri"]);
                tot.expected_ieri += parseInt(res["all"][prodotto][type_campaign]["expected_ieri"]);
            }
            tot.perc_totale = (((tot.expected > 0 ) ? (tot.totale / tot.expected) : 1) * 100).toFixed(2);
            tot.perc_totale_ieri = (((tot.expected_ieri > 0 ) ? (tot.totale_ieri / tot.expected_ieri) : 1) * 100).toFixed(2);

            if(tot.perc_totale > 100) tot.perc_totale = 100;
            if(tot.perc_totale_ieri > 100) tot.perc_totale_ieri = 100;

            rows.push(
                <TableRow>
                    <TableCell className="cell-separator"><hr width="100%"/>TOTALE</TableCell>
                    <TableCell className="cell-separator"><hr width="100%"/>TOTALE</TableCell>
                    <TableCell className="cell-separator"><hr width="100%"/>TOTALE</TableCell>
                    <TableCell className="cell-separator"><hr width="100%"/>TOTALE</TableCell>
                    <TableCell className="cell-separator"><hr width="100%"/>TOTALE</TableCell>
                    <TableCell className="cell-separator test"><hr width="100%"/><Box component={'div'} className={(tot["totale"] - tot["expected"] < 0) ? "red-div" : "green-div"}>{tot["totale"] + " / "+ tot["expected"]}</Box></TableCell>
                    <TableCell className="cell-separator"><hr width="100%"/><Box component={'div'} className={(tot.perc_totale < 100) ? "red-div" : "green-div"}>{tot.perc_totale + " %"}</Box></TableCell>
                    <TableCell className="cell-separator"><hr width="100%"/><Box component={'div'} className={(tot["totale_ieri"] - tot["expected_ieri"] < 0) ? "red-div" : "green-div"}>{tot["totale_ieri"] + " / "+ tot["expected_ieri"]}</Box></TableCell>
                    <TableCell className="cell-separator"><hr width="100%"/><Box component={'div'} className={(tot.perc_totale_ieri < 100) ? "red-div" : "green-div"}>{tot.perc_totale_ieri + " %"}</Box></TableCell>
                </TableRow>
            );
            

            //separatore prodotto
            if(index_prodotto < Object.keys(res["single"]).length - 1){
                rows.push(
                    <TableRow>
                        <TableCell className="cell-separator"><hr className="hr-separator" width="100%"/></TableCell>
                        <TableCell className="cell-separator"><hr className="hr-separator" width="100%"/></TableCell>
                        <TableCell className="cell-separator"><hr className="hr-separator" width="100%"/></TableCell>
                        <TableCell className="cell-separator"><hr className="hr-separator" width="100%"/></TableCell>
                        <TableCell className="cell-separator"><hr className="hr-separator" width="100%"/></TableCell>
                        <TableCell className="cell-separator"><hr className="hr-separator" width="100%"/></TableCell>
                        <TableCell className="cell-separator"><hr className="hr-separator" width="100%"/></TableCell>
                        <TableCell className="cell-separator"><hr className="hr-separator" width="100%"/></TableCell>
                        <TableCell className="cell-separator"><hr className="hr-separator" width="100%"/></TableCell>
                    </TableRow>
                );
            }
            index_prodotto++;
        }
        setTable(rows);
        setHeader(head);
        props.pageLoader(false);
    }
    
    const downloadFile = async ()  => {
        props.pageLoader(true);
        const res = await postRest(props, 'controller/obiettiviBO', { service: _service, product: _product });

        let csv = "";

    
       csv += "Prodotto;Tipologia Campagna;Campagna;Sito;Liste;Totale;Obiettivo Mensile;Rapporto Percentuale;Totale Ieri;Totale Consigliato;Rapporto Percentuale\n";

       
       let tot = {
           totale: 0,
           expected: 0,
           perc_totale: 0,
           totale_ieri: 0,
           expected_ieri: 0,
           perc_totale_ieri: 0
       };

        for (const prodotto in res["single"]) {
            for (const type_campaign in res["single"][prodotto]) {

    
                tot.totale += parseInt(res["all"][prodotto][type_campaign]["totale"]);
                tot.expected += parseInt(res["all"][prodotto][type_campaign]["expected"]);
                tot.totale_ieri += parseInt(res["all"][prodotto][type_campaign]["totale_ieri"]);
                tot.expected_ieri += parseInt(res["all"][prodotto][type_campaign]["expected_ieri"]);

                tot.perc_totale = (((tot.expected > 0 ) ? (tot.totale / tot.expected) : 1) * 100).toFixed(2);
                tot.perc_totale_ieri = (((tot.expected_ieri > 0 ) ? (tot.totale_ieri / tot.expected_ieri) : 1) * 100).toFixed(2);
    
                if(tot.perc_totale > 100) tot.perc_totale = 100;
                if(tot.perc_totale_ieri > 100) tot.perc_totale_ieri = 100;

                for (const campagna in res["single"][prodotto][type_campaign]) {
                    for (const sito in res["single"][prodotto][type_campaign][campagna]) {
                        for(const lista in res["single"][prodotto][type_campaign][campagna][sito]["lists"]){
                            if(lista != 'rimanenti'){
                                csv += prodotto + ";" + type_campaign + ";" + campagna + ";" + sito + ";" + lista + ";";
                                csv += res["single"][prodotto][type_campaign][campagna][sito]['lists'][lista].totale + ";";
                                csv += res["single"][prodotto][type_campaign][campagna][sito]['lists'][lista].expected + ";";
                                csv += res["single"][prodotto][type_campaign][campagna][sito]['lists'][lista].perc_totale + "%;";
                                csv += res["single"][prodotto][type_campaign][campagna][sito]['lists'][lista].totale_ieri + ";";
                                csv += res["single"][prodotto][type_campaign][campagna][sito]['lists'][lista].expected_ieri + ";";
                                csv += res["single"][prodotto][type_campaign][campagna][sito]['lists'][lista].perc_totale_ieri + "%\n";
                            }
                        }
                        csv += prodotto + ";" + type_campaign + ";" + campagna + ";" + sito + ";Rimanenti;";
                        csv += res["single"][prodotto][type_campaign][campagna][sito]['lists']['rimanenti'].totale + ";";
                        csv += res["single"][prodotto][type_campaign][campagna][sito]['lists']['rimanenti'].expected + ";";
                        csv += res["single"][prodotto][type_campaign][campagna][sito]['lists']['rimanenti'].perc_totale + "%;";
                        csv += res["single"][prodotto][type_campaign][campagna][sito]['lists']['rimanenti'].totale_ieri + ";";
                        csv += res["single"][prodotto][type_campaign][campagna][sito]['lists']['rimanenti'].expected_ieri + ";";
                        csv += res["single"][prodotto][type_campaign][campagna][sito]['lists']['rimanenti'].perc_totale_ieri + "%\n";
                    }
                }
                csv += "TOTALE;TOTALE;TOTALE;TOTALE;TOTALE;" + res["all"][prodotto][type_campaign].totale + ";" + res["all"][prodotto][type_campaign].expected + ";" + res["all"][prodotto][type_campaign].perc_totale + "%;" + res["all"][prodotto][type_campaign].totale_ieri + ";" + res["all"][prodotto][type_campaign].expected_ieri + ";" + res["all"][prodotto][type_campaign].perc_totale_ieri + "%\n\n";
            }
            csv += "TOTALE" + ";" + "TOTALE" + ";" + "TOTALE" + ";" + "TOTALE" + ";" + "TOTALE;" + tot.totale + ";" + tot.expected + ";" + tot.perc_totale + "%;" + tot.totale_ieri + ";" + tot.expected_ieri + ";" + tot.perc_totale_ieri + "%\n\n";
            csv += "\n";
        }
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
        element.setAttribute('download', 'Obiettivo_Mensile_'+Date.now()+'.csv');
    
        element.style.display = 'none';
        document.body.appendChild(element);
    
        element.click();
    
        document.body.removeChild(element);
        props.pageLoader(false);
    }
    
    const getServices = async () => {
        const prod = [];
        const services = await postRest(props, 'controller/obiettiviBO', { get_services: 1 });
        for (const iterator of services.services) {
            prod.push(<MenuItem value={iterator.value}>{iterator.name}</MenuItem>);
        }
        setServices(prod);
    }
    const getProducts = async () => {
        const prod = [];
        const products = await postRest(props, 'controller/obiettiviBO', { service: _service, get_product: 1 });
        for (const iterator of products.products) {
            prod.push(<MenuItem value={iterator.value}>{iterator.name}</MenuItem>);
        }
        setProducts(prod);
    }
    const getTarget = async () => {
        props.pageLoader(true);
        
        const res = await postRest(props, 'controller/obiettiviBO', { get_target: 1 });
        setProgress(res);
        if (isset(_product)) {
            setProductValue(_product)
        }
        props.pageLoader(false);
    }

    const changeService = (event) => {
        navigate('/obiettiviBO/'+event.target.value+'/ALL');
    }
    const changeProducts = (event) => {
        navigate('/obiettiviBO/'+_service+'/'+event.target.value);
    }

    const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 400 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 5,
          background: "var(--bg-gradient-primary)",
        },
      }));

    const LinearProgressWithLabel = (props) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <CustomLinearProgress sx={{height: 10, borderRadius: 5, backgroundColor: "black"}} size={40} thickness={4} variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2">{`${Math.round(
                props.value,
              )}%`}</Typography>
            </Box>
          </Box>
        );
      }
      
    return (
        <Box component={'div'}className="mb-2">
            <Box component={'div'} className='cursor-pointer mb-3'>
                <Button onClick={backLink} color="primary"> <ArrowCircleLeftIcon/>&nbsp; Torna Indietro </Button> 
            </Box>
            <Box component={'div'} >
                <Box component={'div'} className="fc-header-toolbar fc-toolbar">
                    <Grid container className='row mx-1 mt-2'>
                        <Grid item xs={12} md={4} />
                        <Grid item xs={12} md={4} sx={{textAlign: "center"}}>
                        <InputLabel id={'columns_helper_label'}>Avanzamento Target</InputLabel>
                            <LinearProgressWithLabel value={progress} />
                            <br />
                            <Box component={'div'} sx={{textAlign: "center"}}>
                                <Box component={'i'} sx={{fontSize: '13px'}} className="obiettiviBo">( Viene eseguito un aggiornamento degli ultimi 3 giorni ogni ora, una volta al giorno riesegue i calcoli basandosi sugli ultimi 40 giorni )</Box>
                            </Box>
                            <br />
                            <br />
                        </Grid>
                        <Grid item xs={12} md={4} sx={{marginBottom: 15}}/>
                        <br />
                        <br/>
                        <Grid item xs={12} md={6}>
                            <Box component={'h2'} >{currentYearMonth}</Box>
                        </Grid>
                        <Grid item xs={12} md={2} >
                            <FormControl className='form-control' size="small" >
                                <InputLabel id={'columns_helper_label'}>Servizi</InputLabel>
                                <Select
                                    labelId={'columns_helper_label'}
                                    value={serviceValue}
                                    defaultValue={serviceValue}
                                    onChange={changeService}
                                >{services}</Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2} >
                            <FormControl className='form-control' size="small" >
                                <InputLabel id={'columns_helper_label'}>Prodotti</InputLabel>
                                <Select
                                    labelId={'columns_helper_label'}
                                    value={productValue}
                                    defaultValue={productValue}
                                    onChange={changeProducts}
                                >{products}</Select>
                            </FormControl>
                        </Grid>
                        <Grid item xl={3}  >
                            {
                                header.length > 0 && 
                                <Button className="btn btn-warning" sx={{marginLeft:2}} onClick={downloadFile}><FileDownload /> Download</Button>
                            }
                        </Grid>
                    </Grid>
                </Box>

                <Box component={'div'} className="">
                    <Box component={'div'} className="table-scroll">
                       
                        <Table className="table separator align-items-center">
                            <TableHead>
                                <TableRow>
                                    {header}
                                </TableRow>
                            </TableHead>
                            <TableBody>{calendar}</TableBody>
                        </Table>
                            
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}


export default ObiettiviBO;