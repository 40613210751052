import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {isset} from '../../../Service/standard';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import * as Standard from '../../../Service/standard';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export const Items = (props) => {
    const item = [];
    let i = 0;
    for (const field of props.content) {
        item.push(
            <TableCell key={i} className={props.cash && Standard.IsNumeric(field) ? ((field >= 0) ? 'text-success' : 'text-danger') : ''}>
                {(!props.cash || !Standard.IsNumeric(field)) && field}
                {
                    (props.cash && Standard.IsNumeric(field)) &&
                    <React.Fragment>
                        {'€ ' + field.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                        {field >= 0 ? <ArrowUpwardIcon color='#4CAF50' fontSize='small' /> : <ArrowDownwardIcon color='#F44335' fontSize='small' />}
                    </React.Fragment>
                    
                
                }
            </TableCell>
        )
        i++;
    }
    return item;
}

const BasicTable = (props) => {
    const navigate = useNavigate();
    const header = [];
    const item = [];
    let i = 0;
    for (const item of props.headers) {
        header.push(
            <TableCell  className="text-center" key={item.key}>{item.headerName}</TableCell>
        )
    }
    for (const content of props.items) {
        if(isset(content.link) && content.link.length > 0){
            item.push(
                <TableRow key={i} onClick={() => {navigate(content.link)}}>
                    {<Items className="text-center" content={content.items} />}
                </TableRow>
            )
        }else if(isset(content.function) && content.function.length > 0){
            item.push(
                <TableRow key={i} 
                onClick={() => {Standard[content.function](props, content.params)}}
                >
                    {<Items className="text-center" content={content.items} />}
                </TableRow>
            )
        }else{
            item.push(
                <TableRow key={i}>
                    {<Items className="text-center" content={content.items} cash={props.cash} />}
                </TableRow>
            )
        }
        i++;
    }

    return (
        <TableContainer component={Paper} sx={{ backgroundColor: 'transparent' }}>
            <Table aria-label="simple table" className='table table-hover align-items-center'>
                <TableHead>
                    <TableRow>
                        {header}
                    </TableRow>
                </TableHead>
                <TableBody className="cursor-pointer">
                    {item}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default BasicTable;