import Box from '@mui/material/Box';
import * as React from 'react';

import './../../bootstrap.css';
import NavBar from '../includes/NavBar/navbar';
import SideBar from '../includes/SideBar/sidebar';
import { postRest } from '../../Service/callRest';

const Main = (props) => {
  const [classes, setClasses] = React.useState('g-sidenav-show ');
  let notTecnico = false;
  // const classes = 'g-sidenav-show ';
  const showMenuHandler = () => {
    if (classes === 'g-sidenav-show ') {
      setClasses('g-sidenav-show g-sidenav-pinned ');
    } else {
      setClasses('g-sidenav-show ');
    }
  }
  const hideBodyMenuHandler = () => {
    if (classes === 'g-sidenav-show g-sidenav-pinned ') {
      setClasses('g-sidenav-show ');
    }
  }
  const tt = async () => {
    const response = await postRest(props,   `controller/notIsTecnico`);
    notTecnico = response;
  };
  document.addEventListener('keydown', (event) => {
    if(event.keyCode === 123){
      if(notTecnico === true){
        event.preventDefault();
      }
    }
  })
  return (
    <Box component={'div'} className={classes} onContextMenu={(event) => {event.preventDefault()}} onLoad={tt}>
      <SideBar showMenu={showMenuHandler} onLogout={props.onLogout}></SideBar>
      <Box component={'main'} className='main-content position-relative max-height-vh-100 h-100 border-radius-lg'>
        <NavBar name={props.name} showMenu={showMenuHandler} onChangeTheme={props.onChangeTheme} theme={props.theme} />
        <Box component={'div'} className='container-fluid py-4' sx={{ minHeight: "85vh" }} onClick={hideBodyMenuHandler}>
          {props.children}
        <Box component={'div'} sx={{opacity: 0.7}}>© {new Date().getFullYear()} by Comverta Development Team</Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Main;