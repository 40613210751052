import { useNavigate, useParams } from 'react-router-dom';
import { postRest } from '../../Service/callRest';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React from 'react';
import PType from '../includes/PType/ptype';
import Section from '../includes/Section/section';
import { Button } from '@mui/material';
import { IsNumeric, getCookie, isset } from '../../Service/standard';
import Swal from 'sweetalert2';
import OptionButton from '../includes/OptionButton/optionbutton';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

const Single = (props) => {

  const { _obj } = useParams();
  const { _id } = useParams();
  let { _mode } = useParams();
  const { _thisObj } = useParams();
  const { _thisId } = useParams();
  const navigate = useNavigate();

  const [objName, setobjName] = React.useState('');
  const [redirect, setredirect] = React.useState('');
  const [sections, setSections] = React.useState([]);
  const [fields, setFields] = React.useState([]);
  const [buttons, setButtons] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [classSave, setClassSave] = React.useState('btn btn-success w-100');

  const params = {
    Table: _obj,
    id: _id,
    obj: _thisObj,
    cod_obj: _thisId,
    mode: _mode
  };
  const setLoadingHandler = (value) => {
    props.pageLoader(value);
  }

  const temp = [];
  const sect = [];

  let loading = true;
  if (isLoaded === false) {
    props.pageLoader(true);
  }
  const createSingle = async () => {
    if (loading === true) {
      loading = false;
      const res = await postRest(props, 'controller/single', params);
      setobjName(res.objName);
      setredirect(res.redirect);
      setButtons([]);

      if (isset(res.buttonsSingle) && _mode === 'single') {
        let i = 0;
        const tmppp = [];
        const btns = res.buttonsSingle.split(',');
        if (btns.length > 0) {
          for (const iterator of btns) {
            tmppp.push(<OptionButton props={{ buttons: iterator, obj: _obj, id: _id }} loading={setLoadingHandler} key={i} />);
            i++;
          }
          setButtons(tmppp);
        }
      }

      setFields([]);
      for (const val of res.fields) {
        temp.push(
          <PType props={val} isDisabled={(_mode === 'single') ? true : (isset(val.disabled) ? val.disabled : false)} key={val.name} />
        );
      }
      setFields(temp);
      setSections([]);
      if (isset(res.sections)) {
        for (const val of res.sections) {
          sect.push(
            <Section val={val} key={val.title} thisObj={_obj} thisId={_id} />
          );
        }
        setSections(sect);
      }
      loading = true;
      props.pageLoader(false);
      setIsLoaded(true);
    };

    if (parseInt(_id) === 0) {
      _mode = 'edit';
    }

  }

  const save = async () => {
    props.pageLoader(true);
    setClassSave('btn btn-success w-100 disabled');
    const obj = {};
    if (parseInt(_id) > 0) {
      obj['id'] = _id;
    }
    for (const iterator of fields) {
      let props = iterator.props.props;
      obj[props.name] = props.value
    }


    if (isset(obj['file']) && obj['file'].getAll('files[]').length > 0) {
      const file = await postRest(props, 'controller/upload', obj['file']);

      let files = '';
      for (const iterator of obj['file'].getAll('files[]')) {
        files += iterator.name + ';';
      }
      if (file.success === true) {
        obj['file'] = { files: files.slice(0, -1), time: file.time };
      } else {
        obj['file'] = '';
      }
    }

    const params = {
      Table: _obj,
      editData: JSON.stringify(obj),
      filters: getCookie('filters')
    };
    const res = await postRest(props, 'controller/save', params);
    props.pageLoader(false);
    setClassSave('btn btn-success w-100');
    if (IsNumeric(res)) {
      if(redirect.length == 0){
        if (parseInt(_id) > 0) {
          navigate('/single/' + _obj + '/' + _id);
          _mode = 'single';
        } else {
          if (_thisObj !== undefined) {
            navigate('/single/' + _thisObj + '/' + _thisId);
            _mode = 'single';
          } else {
            navigate('/tableList/' + _obj);
          }
        }
      }else{
        navigate(redirect);
      }
    } else {
      Swal.fire({
        title: 'Impossibile Salvare',
        html: res,
        icon: 'warning',
      });
    }

  }

  const backLink = () => {
   navigate( -1 );
  }

  React.useEffect(() => {
    setButtons([]);
    setFields([]);
    setSections([]);
    setIsLoaded(false);
    createSingle();
  }, [_obj, _id, _mode]);


  return (
    <React.Fragment>
      <Grid container>
        <Box component={'div'} className='cursor-pointer mb-3'>
          <Button onClick={backLink} color="primary"> <ArrowCircleLeftIcon/>&nbsp; Torna Indietro </Button> 
        </Box>
        <Grid item xs={12}>
          <Box component={'div'} className='card my-4'>
            <Box component={'div'} className='card-header p-0 position-relative mt-n4 mx-3 z-index-2'>
              <Box component={'div'} className='bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3'>
                <Box component={'h6'} className='text-white text-capitalize ps-3 text-center fs-6'>{objName}</Box>
              </Box>
            </Box>
            <Box component={'div'} className='m-4 row box'>
              {
                _mode == 'single' &&
                <Box component={'div'} className='buttons-single'>
                  {buttons}
                </Box>
              }
              {fields}
              {_mode == 'edit' &&
                <Box component={'div'} className='buttons'>
                  <Button
                    onClick={save}
                    className={classSave}
                  >Salva</Button>
                </Box>
              }
            </Box>

          </Box>
        </Grid>
      </Grid>
      {_mode == 'single' &&

        <Grid container className='row mx-1 mt-2'>
          {sections}
        </Grid>
      }
    </React.Fragment>
  );
}

export default Single;