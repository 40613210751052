import { Box, Button, Grid, Select, InputLabel, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { getMese } from "../../Service/standard";
import { dateToString } from "../../Service/standard";
import { isset } from "../../Service/standard";
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { postRest } from "../../Service/callRest";
import PType from '../includes/PType/ptype';
import FileDownload from "@mui/icons-material/FileDownload";



const CalendarPrenotazioni = (props) => {

    const [currentDateMonth, setCurrentDateMonth] = useState(dateToString(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-01'));
    const [currentYearMonth, setCurrentYearMonth] = useState(getMese(new Date(currentDateMonth).getMonth() + 1) + ' ' + new Date(currentDateMonth).getFullYear());
    const [calendar, setCalendar] = useState([]);
    const [header, setHeader] = useState([]);
    const [items, setItems] = useState([]);
    const navigate = useNavigate();
    const { _project } = useParams();
    const [columnsValue, setColumnsValue] = useState(0);

    const preview = () => {
        setCurrentDateMonth(dateToString(new Date(currentDateMonth).setMonth(new Date(currentDateMonth).getMonth() - 1)));
    }
    const next = () => {
        setCurrentDateMonth(dateToString(new Date(currentDateMonth).setMonth(new Date(currentDateMonth).getMonth() + 1)));
    }
    const today = () => {
        setCurrentDateMonth(dateToString(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-01'));
    }
    

    useEffect(() => {
        setCurrentYearMonth(getMese(new Date(currentDateMonth).getMonth() + 1) + ' ' + new Date(currentDateMonth).getFullYear());
        getProject()
        if (isset(_project)) {
            createCalendar();
        }
    }, [currentDateMonth,_project]);

    const backLink = () => {
        navigate( -1 );
    }

    const moveToPrenotazione = (value, check = false, mode = 'edit') => {
        if (check === true) {
            navigate('/' + mode + '/PrenotazioniPostazioni/' + value);
        }
    }

    const createCalendar = async () => {
        props.pageLoader(true);
        let rows = [];
        
        const res = await postRest(props, 'controller/calendarPrenotazioni', { date_from: dateToString(currentDateMonth, 'en', true), project: _project, calendar: 1 });
        let head = []
        let classcell = 'text-center';
        let getDateToday = new Date().getDate();
        let getMonthToday = new Date().getMonth() + 1;
        head.push(<TableCell className={classcell} key={'op'}>Operatore</TableCell>);
        for (let index = 0; index < res.days.length; index++) {
            if (getDateToday == index + 1 && getMonthToday == res.month) {
                classcell = 'text-center border-tab';
            } else {
                classcell = 'text-center';
            }
            head.push(<TableCell className={classcell} key={index}>{res.days[index]}<br/>{(index+1)}</TableCell>);    
        }
        for (const iterator in res.employees) {
            let rows2 = [];
            for (const i in res.employees[iterator].date) {
                let iconCalendar = '-';
                let back = '';
                let checkMove = false;
                let mode = 'edit';
                if (getDateToday == new Date(i).getDate() && getMonthToday == res.month) {
                    back += 'border-tab ';
                }
                if (res.employees[iterator].date[i][0] == 1) {
                    back += 'bg-success-light';
                    iconCalendar = (<CheckIcon sx={{ fontSize: 20 }} />);
                } else if(res.employees[iterator].date[i][0] == 2){
                    back += 'bg-danger-light cursor-pointer';
                    checkMove = true;
                    mode = 'single';
                    iconCalendar = (<ClearIcon sx={{ fontSize: 20 }} />);
                } else if(res.employees[iterator].date[i][0] == 0){
                    back += 'bg-info-light cursor-pointer'
                    iconCalendar = (<AccessTimeIcon sx={{ fontSize: 20 }} />);
                    checkMove = true;
                }
                
                rows2.push(<TableCell className={back} onClick={() => {moveToPrenotazione(res.employees[iterator].date[i][1],checkMove,mode)}} key={'employees_'+iterator+i}>{iconCalendar}</TableCell>)
            }
            rows.push(
                <TableRow>
                    <TableCell  key={'employees'+iterator}>{res.employees[iterator].name}</TableCell>
                    {rows2}
                </TableRow>
            );
        }
        setCalendar(rows);
        setHeader(head);
        props.pageLoader(false);
    }
    
    const downloadFile = async ()  => {
        props.pageLoader(true);
        const res = await postRest(props, 'controller/calendarPrenotazioni', { date_from: dateToString(currentDateMonth, 'en', true), project: _project, calendar: 0 });

        let csv = "";

    
       csv += "Operatore";
        for (let index = 0; index < res.days.length; index++) {
            csv += ";"+(res.days[index]+'/'+(index+1));    
        }
        csv += "\n";
        for (const iterator in res.employees) {
            let rows2 = [];
            for (const i in res.employees[iterator].date) {
                let presenza = '-';
                
                if (res.employees[iterator].date[i][0] == 1) {
                    presenza = "Convalidato";
                } else if(res.employees[iterator].date[i][0] == 2){
                    presenza = "Non Convalidato";
                } else if(res.employees[iterator].date[i][0] == 0){                  
                    presenza = "In Validazione";   
                }
                
                rows2.push(presenza);
            }
            csv += res.employees[iterator].name;

            rows2.map((item) => {
                csv += ";" + item;
            });
            csv += "\n";
        }
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
        element.setAttribute('download', 'Export_Prenotazioni_'+Date.now()+'.csv');
    
        element.style.display = 'none';
        document.body.appendChild(element);
    
        element.click();
    
        document.body.removeChild(element);
        props.pageLoader(false);
    }
    
    const getProject = async () => {
        props.pageLoader(true);
        let rows = [];
        
        const res = await postRest(props, 'controller/calendarPrenotazioni', { get_projects: 1 });
        for (const iterator of res) {
            rows.push(<MenuItem value={iterator.id} key={iterator.id}>{iterator.name} </MenuItem>);
        }
        setItems(rows);
        if (isset(_project)) {
            setColumnsValue(_project)
        }
        props.pageLoader(false);
    }

    const changeColumnHandler = (event) => {
        navigate('/calendarPrenotazioni/'+event.target.value);
    }

    return (
        <Box component={'div'}className="mb-2">
            <Box component={'div'} className='cursor-pointer mb-3'>
                <Button onClick={backLink} color="primary"> <ArrowCircleLeftIcon/>&nbsp; Torna Indietro </Button> 
            </Box>
            <Box component={'div'} className="fc fc-media-screen fc-direction-ltr fc-theme-standard" >
                <Box component={'div'} className="fc-header-toolbar fc-toolbar">
                    <Grid container>
                        <Grid item xl={4} className="fc-toolbar-chunk">
                            <Box component={'h2'} className="fc-toolbar-title">{currentYearMonth}</Box>
                        </Grid>
                        <Grid item xl={4} className="fc-toolbar-chunk">
                        <InputLabel id={'columns_helper_label'}>PROGETTI</InputLabel>
                        <Select
                            labelId={'columns_helper_label'}
                            value={columnsValue}
                            defaultValue={columnsValue}
                            onChange={changeColumnHandler}
                        >
                            {items}
                        </Select>
                        </Grid>
                        <Grid item xl={3} className="fc-toolbar-chunk" >
                            <Button className="btn btn-info" onClick={today}>Oggi</Button>
                            <Box component={'div'} className="fc-button-group">
                                <Button className="btn btn-info fc-button" onClick={preview}><ArrowBackIosNewIcon /></Button>
                                <Button className="btn btn-info fc-button" onClick={next}><ArrowForwardIosIcon /></Button>
                            </Box>
                            {
                                header.length > 0 && 
                                <Button className="btn btn-warning" sx={{marginLeft:2}} onClick={downloadFile}><FileDownload /> Download</Button>
                            }
                        </Grid>
                    </Grid>
                </Box>

                <Box component={'div'} className="fc-view-harness fc-view-harness-active">
                    <Box component={'div'} className="fc-dayGridMonth-view fc-view fc-daygrid table-scroll">
                       
                        <Table className="table align-items-center table-opacity-mobile">
                            <TableHead>
                                <TableRow>
                                    {header}
                                </TableRow>
                            </TableHead>
                            <TableBody>{calendar}</TableBody>
                        </Table>
                            
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}


export default CalendarPrenotazioni;