import { Box, Button, Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { useEffect, useState } from "react";
import { getMese } from "../../Service/standard";
import { dateToString } from "../../Service/standard";
import { isset } from "../../Service/standard";
import { postRest } from "../../Service/callRest";
import { useNavigate } from 'react-router-dom';


const CalendarPrenotazioniPersonali = (props) => {

    const [currentDateMonth, setCurrentDateMonth] = useState(dateToString(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-01'));
    const [currentYearMonth, setCurrentYearMonth] = useState(getMese(new Date(currentDateMonth).getMonth() + 1) + ' ' + new Date(currentDateMonth).getFullYear());
    const [calendar, setCalendar] = useState([]);
    const navigate = useNavigate();

    const preview = () => {
        setCurrentDateMonth(dateToString(new Date(currentDateMonth).setMonth(new Date(currentDateMonth).getMonth() - 1)));
    }
    const next = () => {
        setCurrentDateMonth(dateToString(new Date(currentDateMonth).setMonth(new Date(currentDateMonth).getMonth() + 1)));
    }
    const today = () => {
        setCurrentDateMonth(dateToString(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-01'));
    }

    useEffect(() => {
        setCurrentYearMonth(getMese(new Date(currentDateMonth).getMonth() + 1) + ' ' + new Date(currentDateMonth).getFullYear());
        createCalendar();
    }, [currentDateMonth]);

    const createCalendar = async () => {
        props.pageLoader(true);
        let rows = [];
        const max = new Date(new Date(currentDateMonth).getFullYear(), (new Date(currentDateMonth).getMonth() + 1), 0).getDate();
        const res = await postRest(props, 'controller/calendarioPrenotazioniPersonale', { date_from: dateToString(currentDateMonth, 'en', true) });


        let sett = [];
        let nset = 0;
        for (let i = 0; i < max; i += 7) {
            for (let j = i; j < i + 7; j++) {
                if (j < max) {
                    if (!isset(sett[nset])) sett[nset] = [];
                    let index = ((new Date(new Date(currentDateMonth).getFullYear(), (new Date(currentDateMonth).getMonth()), j + 1).getDay()) + 6) % 7;

                    let curdate = dateToString(new Date(new Date(currentDateMonth).getFullYear(), (new Date(currentDateMonth).getMonth()), j + 1), 'en', true);

                    let className = "text-center align-items-center calendar";
                    if (curdate == dateToString(new Date(), 'en', true)) {
                        className = "text-center align-items-center calendar-today";
                    }

                    sett[nset][index] = (
                        <TableCell className={className} sx={{ width: '100px' }} key={curdate} id={curdate}>
                            <Grid container>
                                <Grid item xl={4} md={0} />

                                <Grid item xl={4} md={12}>
                                    <Box component={'div'} >{j + 1}</Box>
                                </Grid>

                                <Grid item xl={4} md={0} />

                                <Grid item xl={3} md={0} />
                                <Grid item xl={6} md={12} >
                                    <Box component={'hr'} className="mt-1" />
                                </Grid>

                                <Grid item xl={3} md={0} />

                                {isset(res[curdate]) &&
                                    res[curdate].map((item) => {
                                        return <Grid item sm={12} className={"cursor-pointer event-field " + item.color} onClick={goTo} id={item.id} key={item.id} >{item.orario_inizio + '\n - \n' + item.orario_fine}</Grid>
                                    })
                                }
                            </Grid>
                        </TableCell>
                    );
                    if (index == 6) {
                        nset += 1;
                    }
                }
            }
            for (let z = 0; z < sett.length; z++) {
                for (let k = 0; k < 7; k++) {
                    if (!isset(sett[z][k])) {
                        sett[z][k] = <TableCell className="calendar" key={k}></TableCell>;
                    }
                }
            }
        }
        let key = 0;
        sett.map((item) => {
            rows.push(<TableRow key={key}>
                {item.map((i) => { return i; })}
            </TableRow>)
            key++;
        });

        setCalendar(rows);
        props.pageLoader(false);
    }

    const backLink = () => {
        navigate( -1 );
    }

    const goTo = (event) => {
        navigate('/single/Bookings/' + event.target.id);
    }
    return (
        <Box component={'div'}>
             <Box component={'div'} className='cursor-pointer mb-3'>
                <Button onClick={backLink} color="primary"> <ArrowCircleLeftIcon/>&nbsp; Torna Indietro </Button> 
            </Box>
            <Box component={'div'} className="fc fc-media-screen fc-direction-ltr fc-theme-standard" >
                <Box component={'div'} className="fc-header-toolbar fc-toolbar">
                    <Grid container>
                        <Grid item xl={4} className="fc-toolbar-chunk">
                            <Box component={'h2'} className="fc-toolbar-title">{currentYearMonth}</Box>
                        </Grid>
                        <Grid item xl={4} className="fc-toolbar-chunk" />
                        <Grid item xl={3} className="fc-toolbar-chunk" >
                            <Button className="btn btn-info" onClick={today}>Oggi</Button>
                            <Box component={'div'} className="fc-button-group">
                                <Button className="btn btn-info fc-button" onClick={preview}><ArrowBackIosNewIcon /></Button>
                                <Button className="btn btn-info fc-button" onClick={next}><ArrowForwardIosIcon /></Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box component={'div'} className="fc-view-harness fc-view-harness-active">
                    <Box component={'div'} className="fc-dayGridMonth-view fc-view fc-daygrid">
                        <Grid container>
                            <Grid item xs={12} sx={{ height: '750px', overflow: 'auto' }}>
                                <Table className="table align-items-center table-opacity-mobile">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="text-center" key={'lun'}>Lun</TableCell>
                                            <TableCell className="text-center" key={'mar'}>Mar</TableCell>
                                            <TableCell className="text-center" key={'mer'}>Mer</TableCell>
                                            <TableCell className="text-center" key={'gio'}>Gio</TableCell>
                                            <TableCell className="text-center" key={'ven'}>Ven</TableCell>
                                            <TableCell className="text-center" key={'sab'}>Sab</TableCell>
                                            <TableCell className="text-center" key={'dom'}>Dom</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>{calendar}</TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}


export default CalendarPrenotazioniPersonali;