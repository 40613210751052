import { Box, Button, Grid } from "@mui/material";
import PType from "../includes/PType/ptype";
import { useEffect, useState } from "react";
import { IsNumeric, dateToString, isset } from "../../Service/standard";
import { postRest } from "../../Service/callRest";
import Swal from "sweetalert2";


const EventBox = (props) => {

    const [box, setBox] = useState([]);

    let title = '';
    let date_from = '';
    let date_to = '';
    let note = '';
    let events = [];

    const createBox = async () => {

        props.pageLoader(true);


        const res = await postRest(props, 'controller/calendar', { date_single: dateToString(props.data, 'en', true) });

        const tmp = [];


        if (isset(res[dateToString(props.data, 'en', true)])) {
            for (const iterator of res[dateToString(props.data, 'en', true)]) {
                events[iterator.id] = {
                    title: iterator.title,
                    date_from: iterator.date_from,
                    date_to: iterator.date_to,
                    note: iterator.note,
                };
                tmp.push(
                    <Grid container className="m-3 mt-1 p-2" sx={{ border: 'solid 1px', borderRadius: '10px' }}>
                        <PType
                            props={{
                                type: 'text',
                                name: 'title',
                                label: 'Titolo',
                                value: iterator.title,
                                columns: 12
                            }}
                            onChange={(newValue) => { events[iterator.id].title = (newValue); }}
                            isDisabled={false}
                        />
                        <PType
                            props={{
                                type: 'datetime',
                                name: 'date_from',
                                label: 'Dal',
                                value: iterator.date_from,
                                columns: 12
                            }}
                            isDisabled={false}
                            onChange={(newValue) => { events[iterator.id].date_from = dateToString(newValue); }}
                            format={'DD/MM/YYYY HH:mm'}
                        />
                        <PType
                            props={{
                                type: 'datetime',
                                name: 'date_to',
                                label: 'Al',
                                value: iterator.date_to,
                                columns: 12
                            }}
                            isDisabled={false}
                            onChange={(newValue) => { events[iterator.id].date_to = dateToString(newValue); }}
                            format={'DD/MM/YYYY HH:mm'}
                        />
                        <PType
                            props={{
                                type: 'text',
                                name: 'note',
                                label: 'Note',
                                value: iterator.note,
                                columns: 12
                            }}
                            onChange={(newValue) => { events[iterator.id].note = (newValue); }}
                            isDisabled={false}
                        />
                        <Grid item xs={4} />
                        <Grid item xs={4}>
                            <Button
                                id={iterator.id}
                                onClick={remove}
                                className={"btn btn-danger w-50 m-4"}
                            >Elimina</Button>
                        </Grid>
                    </Grid>
                )
            }
        }

        let tmpbox = (
            <Grid container className="p-1">

                <Grid container className="p-2 text-center" sx={{ marginBottom: '1rem', marginTop: '0', marginLeft: '1rem', marginRight: '1rem' }}>
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                        <Box component={'h5'} >Aggiungi Nuovo Evento</Box>
                    </Grid>
                    <Grid item xs={4} />
                    <PType
                        props={{
                            type: 'text',
                            name: 'title2',
                            label: 'Titolo',
                            value: title,
                            columns: 12
                        }}
                        onChange={(newValue) => { title = (newValue); }}
                        isDisabled={false}
                    />
                    <PType
                        props={{
                            type: 'datetime',
                            name: 'date_from',
                            label: 'Dal',
                            value: new Date(props.data + ' 00:00'),
                            columns: 12
                        }}
                        isDisabled={false}
                        format={'DD/MM/YYYY HH:mm'}
                        onChange={(newValue) => { date_from = dateToString(newValue); }}
                    />
                    <PType
                        props={{
                            type: 'datetime',
                            name: 'date_to',
                            label: 'Al',
                            value: new Date(props.data + ' 23:59'),
                            columns: 12
                        }}
                        isDisabled={false}
                        format={'DD/MM/YYYY HH:mm'}
                        onChange={(newValue) => { date_to = dateToString(newValue); }}
                    />
                    <PType
                        props={{
                            type: 'text',
                            name: 'note',
                            label: 'Note',
                            value: '',
                            columns: 12
                        }}
                        onChange={(newValue) => { note = (newValue); }}
                        isDisabled={false}
                    />
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                        <Button
                            onClick={save}
                            className={"btn btn-success w-50 m-4"}
                        >Salva</Button>
                    </Grid>
                    <Grid item xs={4} />
                </Grid>
                <Grid container className="p-2 text-center">

                    <Grid item xs={4} />
                    <Grid item xs={4}>
                        <Box component={'h5'} >{dateToString(props.data, 'it', true)}</Box>
                    </Grid>
                    <Grid item xs={4} />
                    {tmp}
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                        {tmp.length > 0 && <Button
                            onClick={edit}
                            className={"btn btn-warning w-50 m-2 p-2"}
                        >Modifica</Button>}
                    </Grid>
                </Grid>
            </Grid>

        );
        setBox(tmpbox);
        props.pageLoader(false);
    }

    const remove = (event) => {
        const id = event.currentTarget.id;
        Swal.fire({
            title: 'Cancellazione Evento',
            html: 'Sei sicuro di voler eliminare l\' evento?',
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: 'light-blue',
            confirmButtonText: 'Elimina',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed === true) {
            props.pageLoader(true);
              const params = {
                id: id,
                obj: 'Events'
              };
              const resp = await postRest(props, 'controller/delete', params);
              if (resp.status === true) {
                props.pageLoader(false);
                props.closeEvent();
                props.createCalendar();
              } else {
                props.pageLoader(false);
                Swal.fire({
                  title: 'Impossibile Eliminare',
                  html: resp.message,
                  icon: 'warning',
                });
              }
            }
          });
    }
    const edit = async () => {
   
        props.pageLoader(true);
        const params = {
            edit: 1,
            data: events
        };

        const res = await postRest(props, 'controller/calendar', params);
        if (IsNumeric(res)) {
            props.closeEvent();
            props.createCalendar();
        } else {
            Swal.fire({
                title: 'Impossibile Salvare',
                html: res,
                icon: 'warning',
            });
        }
        props.pageLoader(false);
    }
    const save = async () => {
   
        props.pageLoader(true);
        const params = {
            title: title,
            date_from: date_from,
            date_to: date_to,
            note: note,
            save: 1
        };

        const res = await postRest(props, 'controller/calendar', params);
        if (IsNumeric(res)) {
            props.closeEvent();
            props.createCalendar();
        } else {
            Swal.fire({
                title: 'Impossibile Salvare',
                html: res,
                icon: 'warning',
            });
        }
        props.pageLoader(false);
    }

    useEffect(() => {
        setBox([]);
        createBox();
    }, [props]);



    return (
        <Grid container className="p-1">{box}</Grid>
    );
}


export default EventBox;