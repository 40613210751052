import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton
} from '@mui/x-data-grid';
// import { LicenseInfo } from '@mui/x-data-grid-pro';


import React from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Filters from './Filters/filters';
import { FilterList } from '@mui/icons-material';
import TableButton from '../includes/TableButton/tablebutton';
import Badge from '@mui/material/Badge';

const LocalText = {
  noRowsLabel: "Nessun dato trovato",
  toolbarDensity: "altezza righe",
  toolbarDensityCompact: "Compatto",
  toolbarDensityComfortable: "Largo",
  toolbarExportPrint: "Stampa PDF",
  toolbarExportCSV: "Esporta In CSV",
  toolbarFilters: "Filtri",
  toolbarColumns: "Colonne",
  columnsPanelTextFieldLabel: 'Cerca Colonna',
  columnsPanelTextFieldPlaceholder: 'Nome Colonna',
  columnsPanelShowAllButton: 'Mostra Tutto',
  columnsPanelHideAllButton: 'Nascondi Tutto',
  filterPanelColumns: 'Colonna',
  filterOperatorContains: 'Contiene',
  filterPanelInputLabel: 'Valore',
  filterPanelInputPlaceholder: 'Filtra Valore',
  filterPanelOperator: 'Tipo Filtro',
  filterOperatorEquals: 'Uguale a',
  filterOperatorStartsWith: 'Inizia Con',
  filterOperatorEndsWith: 'Termina Con',
  filterOperatorIsEmpty: 'Vuoto',
  filterOperatorIsNotEmpty: 'Non Vuoto',
  filterOperatorIsAnyOf: 'Insieme di Valori',
}

const TableSingle = (props) => {
  const [pageSize, setPageSize] = React.useState(25);
  const [header, setHeader] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [loadedData, setLoadedData] = React.useState([]);
  const [buttons, setButtons] = React.useState([]);
  const [objName, setobjName] = React.useState(props.obj);
  const navigate = useNavigate();
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});
  let loading = true;
  const createTable = async () => {
    if (loading) {
      loading = false;
      setData([]);
      setLoadedData([]);
      setHeader([]);
      setButtons([]);
      const tmpHeader = [];
      let key = 1;
      const res = props.data;
      setobjName(res.objName);
      let btns = [];
      let btn = res.buttons;
      let overcol = res.overcol;
      let maxcol = 8;
      if (overcol === true) {
        maxcol = 999999;
      }
      if (btn.length > 0 && btn.includes(',')) {
        btn = btn.split(',');
      } else {
        btn = [btn];
      }
      for (const iterator of btn) {
        btns.push(<TableButton obj={props.obj} filters={props.filters} qExtra={props.qExtra} where={props.where} key={iterator} buttons={iterator}></TableButton>)
      }
      
      let visibility = {};
      if (res.header) {
        let col = 1;
        for (const val of res.header) {
          let title = val.title;
          let type = res.keytype[key]; // val.title python
          let isVisible = true;
          if (col >= maxcol || title.startsWith('not_viewable_')) {
            isVisible = false;
          }
          col ++;
          visibility[title] = isVisible;

          tmpHeader.push({
            field: title,
            headerName: title.replaceAll('not_viewable_', '').toUpperCase().replaceAll('_', ' '),
            key: title,
            flex: 2,
            minWidth: 180,
            type: res.keytype[key] === 'datetime' ? 'date' : res.keytype[key],
            myType: res.keytype[key],
            valueGetter: (params) => {
              return (type === 'date' || type === 'datetime') ? new Date(params.row[title]) : `${params.row[title] || ''}`

            },
            valueFormatter: (params) => {
              if (type === 'date') {
                let date = new Date(params.value);
                return String(date.getDate()).padStart(2, '0') + '/' + String((date.getMonth() + 1)).padStart(2, '0') + '/' + date.getFullYear();
              } else if (type === 'datetime') {
                let date = new Date(params.value);
                return String(date.getDate()).padStart(2, '0') + '/' + String((date.getMonth() + 1)).padStart(2, '0') + '/' + date.getFullYear() + ' ' + String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0') + ':' + String(date.getSeconds()).padStart(2, '0');
              } else if (type === 'number' || type === 'real') {
                return Number(params.value).toLocaleString(undefined, { minimumFractionDigits: 2 });
              } else {
                return params.value;
              }
            }
          });
          key++;
        }
      }
      setColumnVisibilityModel(visibility);
      setButtons(btns);
      setData(res.data);
      setLoadedData(res.data);
      setHeader(tmpHeader);
      loading = true;
    }

  };

  const FilterData = (e) => {
    setLoadedData(e);
  }
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [numFilters, setNumFilters] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
    setPopoverOpen(!popoverOpen);
  };
  const handlePopoverClose = (event) => {
    setPopoverOpen(!popoverOpen);
  };
  const handleNumFilters = (n) => {
    setNumFilters(n);
  };
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        {buttons}
        {header.length > 0 && 
        <Badge badgeContent={numFilters} key={'tableFilters'} color="primary">
          <Button onClick={handlePopoverClick}  className="btn btn-info btn-sm rounded-pill">
            <Box component={'span'} className="MuiButton-startIcon MuiButton-iconSizeSmall css-y6rp3m-MuiButton-startIcon">
              <FilterList />
            </Box>
            Filtri
          </Button>
        </Badge>}

        <GridToolbarColumnsButton className="btn btn-purple btn-sm rounded-pill" />
        <GridToolbarExport 
          csvOptions={{
            fileName: objName,
            delimiter: ';',
            utf8WithBom: true,
          }}
          printOptions={{
            hideFooter: true,
            hideToolbar: true,
          }}
          className="btn btn-warning btn-sm rounded-pill" 
        />
      </GridToolbarContainer>
    );
  }

  React.useEffect(() => {
    setNumFilters(0);
    createTable();
  }, [props.obj, props.filters, props.qExtra, props.where]);

  const handleRowClick = (params) => {
    params.row.id = (params.row.id.toString().includes('!')) ? params.row.id.split('!')[0] : params.row.id;
    if (params.row.id > 0) {
      navigate('/single/' + props.obj + '/' + params.row.id);
    } else if (params.row.id.includes('https://')) {
      window.open(
        params.row.id,
        '_blank'
      );
    } else {
      navigate('/' + params.row.id);
    }
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
            <Box component={'div'} className='card-body px-0 pb-2'>
              <Box component={'div'} className='table-responsive'>
                <Filters obj={props.obj} countFilters={handleNumFilters} currentTarget={anchorEl} popoverOpen={popoverOpen} header={header} data={data} onClose={handlePopoverClose} filterData={FilterData} />
                <Box component={'div'} sx={(loadedData.length >= 10) ? {height:'70vh'} : ""} className='table table-hover align-items-center mb-0'>
                  <DataGrid
                    onRowClick={handleRowClick}
                    sx={{ border: 'none' }}
                    className='p-4'
                    columns={header}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setColumnVisibilityModel(newModel)
                    }
                    rows={loadedData}
                    slots={{ toolbar: CustomToolbar }} 
                    disableColumnFilter
                    localeText={LocalText}
                    getRowId={r => r.id}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    pageSizeOptions={[25, 50, 100]}
                    initialState={{
                      pagination: { paginationModel: { pageSize: pageSize } },
                    }}
                    autoHeight={(loadedData.length >= 10) ? false : true}
                    componentsProps={{
                      pagination: {
                        labelRowsPerPage: "Righe per Pagina",
                      }
                    }}
                  />
                </Box>
              </Box>
            </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default TableSingle;