import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { postRest } from './../../Service/callRest';
import { setCookie } from './../../Service/standard';
import { useNavigate } from 'react-router-dom';

import './../../bootstrap.css';
import './../../login.css';

import back from './../../medias/background-login.webp';
import Swal from 'sweetalert2';

const Login = (props) => {
  const [email, setEmail] = React.useState('');
  const [pwd, setPwd] = React.useState('');

  const navigate = useNavigate();
  const emailHandler = (event) => {
    setEmail(event.target.value);
  }
  const pwdHandler = (event) => {
    setPwd(event.target.value);
  }

  document.addEventListener('keydown', (event) => {
    if (event.keyCode === 123 && process.env.NODE_ENV != 'development') {
      event.preventDefault();
    }
  })

  const loginHandler = async (event) => {
    props.pageLoader(true);
    const params = {
      logData:
      {
        'email': email,
        'psw': pwd,
      }
    };

    event.preventDefault();


    const response = await postRest(props, `controller/login`, params);

    if (response !== false) {
      if (response.length < 50) {
        navigate('/OAuth/'+response);
      } else {
        setCookie('code', response, 1);
      }
    }else{
      Swal.fire({
        title: 'Impossibile Accedere',
        html: 'Credenziali Errate',
        icon: 'error',
    });
    }

    props.pageLoader(false);
    props.onLogin(true);

  }
  const handleRecuperaPassword = async (event) => {
    navigate('/passwordRecovery');
  }
  props.pageLoader(false);
  return (
    <Box component={'main'} className='main-content' onContextMenu={(event) => { event.preventDefault() }}>
      <Box component={'div'} className="page-header align-items-start min-vh-100" style={{ backgroundImage: `url(${back})` }}>
        <Box component={'div'} className="container my-auto text-center">
          <Box component={'span'} className="mask bg-gradient-dark opacity-6" />
          <Grid container>
            <Grid item xs={12} lg={4} md={8} className="mx-auto">
              <Box component={'div'} className="container-login">
                <i></i>
                <i></i>
                <i></i>
                <Box component={'div'} className="login">
                  <Box component={'h2'}>Login</Box>


                  <div className="inputBx">
                    <input
                      id="email"
                      placeholder="Email"
                      type="email"
                      defaultValue={email}
                      onChange={emailHandler}
                    />
                  </div>
                  <div className="inputBx">
                    <input
                      id="pwd"
                      placeholder="Password"
                      type="password"
                      defaultValue={pwd}
                      onChange={pwdHandler}
                    />

                  </div>
                  <div className="inputBx"  style={{cursor:'pointer'}}>
                    <Box component={'input'} onClick={loginHandler} onTouchStart={loginHandler} sx={{cursor:'pointer'}} type="submit" value='Sign in' />
                  </div>
                  <Box component={'div'} className="links">
                    <Box
                      component={'p'}
                      sx={{ cursor: 'pointer' }}
                      onClick={handleRecuperaPassword}
                    >
                      Hai dimenticato la password? Clicca qui per recuperarla!
                    </Box>

                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>

  )
}

export default Login;