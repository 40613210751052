import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { dateToString, isset } from '../../../Service/standard';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import * as Standard from '../../../Service/standard';
import 'dayjs/locale/it';
import { Link, Switch } from '@mui/material';


export const Toggle = (props) => {
    const [value, setvalue] = useState(props.value);

    if(isset(props.props.custom)){

        const onChangeHandler = (event) => {
            const currentValue = (value == "1") ? "0" : "1";
            if(isset(props.props.returnOnChangeValue)){
                props.props.returnOnChangeValue(event, currentValue);
            }
            if(document.getElementById(props.id).value == "1"){
    
                document.getElementById(props.id).parentElement.className = document.getElementById(props.id).parentElement.className + ' Mui-checked';
            }else{
                document.getElementById(props.id).parentElement.className = document.getElementById(props.id).parentElement.className.replace(' Mui-checked', '');
            }
        }
    
        const label = { inputProps: { 'id': props.id } };
    
    
        return (
            <Switch {...label}  checked={(document.getElementById(props.id) !== null) ? (document.getElementById(props.id).value == "1" ? true : false) : false} color="success" onChange={onChangeHandler} />
        )
    }else{
        const onChangeHandler = () => {
            const currentValue = (value == "1") ? "0" : "1";
            setvalue(currentValue);
        }
    
        const label = { inputProps: { 'id': props.id } };

        return (
            <Switch {...label} value={value} checked={Boolean(parseInt(value))} color="success" onChange={onChangeHandler} />
        )
    }
}

export const Auto = (props) => {

    const propss = props.props;
    // const [value, setValue] = useState(propss.value);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOptionId, setSelectedOptionId] = useState('');

    function handleSelectChange(event) {
        const selectedOption = event.target.value;
        setSelectedOption(selectedOption);
        const id = propss.options.filter((e) => { return e.label === selectedOption; })[0];
        if (isset(id)) {
            propss.value = id.value;
            setSelectedOptionId(id.value);
        }

        if(isset(propss.onChangeValue)){
            propss.onChangeValue(event, propss.value);
        }
    }
    const options = [];

    let option = '';
    let optionId = '';
    for (const val of propss.options) {

        const isSelected = (val.value == propss.value) ? true : false;
        if (isSelected === true) {
            option = val.label;
            optionId = val.value;
        }

        options.push(
            <option data-value={val.value} value={val.label} key={val.value}></option>
        );
    }

    useEffect(() => {
        setSelectedOption(option);
    }, [propss.label]);

    useEffect(() => {
        setSelectedOptionId(optionId);
        setSelectedOption(option);
    }, [propss.value]);

    return (
        <Grid item xs={12} md={parseInt(propss.columns)} sx={{ marginTop: 2 }} >
            <>
                <TextField
                    disabled={props.isDisabled}
                    className='form-control'
                    sx={{ width: '100%' }}
                    id={propss.name + '_auto'}
                    label={propss.label}
                    color='primary'
                    type='text'
                    size="small"
                    value={selectedOption}
                    onChange={handleSelectChange}
                    inputProps={{ list: "list" + propss.name }}
                />
                <datalist id={"list" + propss.name}>{options}</datalist>
                <input type={'hidden'} id={propss.name} value={selectedOptionId} />
            </>
        </Grid>
    );

}

export const Selection = (props) => {
    const propss = props.props;
    const options = [<MenuItem key="" value="">Seleziona un Elemento</MenuItem>];
    const [value, setValue] = useState((propss.value === null) ? '' : propss.value);
    const handleChange = (event) => {
        setValue(event.target.value);
        propss.value = event.target.value;

        if(isset(propss.onChange)){
            propss.onChange(event);
        }
    };

    

    for (const val of propss.options) {
        options.push(
            <MenuItem value={val.value} key={val.value}>{val.label} </MenuItem>
        );
    }

    return (
        <Grid item xs={12} md={parseInt(propss.columns)} sx={{ marginTop: 2 }} key={propss.name + '_grid'}>
            <FormControl
                className='form-control'
                size="small"
                disabled={props.isDisabled}
            >
                <InputLabel id={propss.name + '_helper_label'}>{propss.label}</InputLabel>
                <Select
                    labelId={propss.name + '_helper_label'}
                    value={value}
                    onChange={handleChange}
                >
                    {options}
                </Select>
            </FormControl>
        </Grid>
    )
}

const PType = (props) => {

    let properties = props.props;
    let result = '';
    // let eventName = (properties.func) ? properties.func.event : '';
    // let name = (properties.func) ? properties.func.name : '';
    const eventName = (properties.func) ? properties.func.event : '';
    const functionName = (properties.func) ? properties.func.name : '';

    dayjs.locale('it');


    const onFileChange = async event => {
        const formData = new FormData();
        for (const iterator of event.target.files) {
            const file = iterator;
            formData.append('files[]', file);
        }
        properties.value = formData;
    };
    
    switch (properties.type) {
        case 'hidden':
            result =
                <input type={'hidden'} id={properties.name} defaultValue={properties.value} />
            break;

        case 'toggle':
            result = 
            <Grid item xs={12} md={parseInt(properties.columns)} sx={{ marginTop: 2 }} key={properties.name + '_grid'}>
                <Toggle 
                    disabled={props.isDisabled}
                    label={properties.label}
                    value={properties.value}
                    id={properties.name}
                    props={props}
                />
            </Grid>;
        break;

        case 'link':
            result =
                <Grid item xs={12} md={parseInt(properties.columns)} sx={{ marginTop: 2 }} key={properties.name + '_grid'}>
                    <Link 
                        className='form-control'
                        sx={{ width: '100%', textAlign:'center' }}
                        color='primary'
                        size="small"
                        target='_blank'
                        id={properties.name}
                        label={properties.label}
                        href={properties.link}
                    >{properties.value}</Link>

                </Grid>;
            break;

        case 'text':
            result =
                <Grid item xs={12} md={parseInt(properties.columns)} sx={{ marginTop: 2 }} key={properties.name + '_grid'}>
                    <TextField
                        disabled={props.isDisabled}
                        className='form-control'
                        sx={{ width: '100%' }}
                        id={properties.name}
                        label={properties.label}
                        color='primary'
                        type='text'
                        size="small"
                        {...{ [eventName]: Standard[functionName] }}
                        defaultValue={properties.value}
                        onChange={(event) => { isset(props.onChange) ? props.onChange(event.target.value) : (isset(props.onChangeEvent) ? props.onChangeEvent(event) : properties.value = event.target.value); }}
                    />

                </Grid>;
            break;

        case 'textarea':
            result =
                <Grid item xs={12} md={parseInt(properties.columns)} sx={{ marginTop: 2 }} key={properties.name + '_grid'}>
                    <TextField
                        disabled={props.isDisabled}
                        className='form-control'
                        sx={{ width: '100%' }}
                        id={properties.name}
                        label={properties.label}
                        color='primary'
                        type='text'
                        size="small"
                        multiline
                        value={properties.value}
                        defaultValue={properties.value}
                        onChange={(event) => { isset(props.onChange) ? props.onChange(event.target.value) : properties.value = event.target.value; }}
                    />

                </Grid>;
            break;


        case 'autocomplete':
            result = <Auto props={properties} isDisabled={props.isDisabled} key={properties.name} />;
            break;

        case 'date':
            result =
                <Grid item xs={12} md={parseInt(properties.columns)} sx={{ marginTop: 2 }} key={properties.name + '_grid'}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {
                            isset(props.onChange) &&
                            <DatePicker disabled={props.isDisabled}
                                className='form-control'
                                value={dayjs(properties.value)}
                                defaultValue={dayjs(properties.value)}
                                sx={{ width: '100%', padding: 0 }}
                                id={properties.name}
                                label={properties.label}
                                format="DD/MM/YYYY"
                                color='primary'
                                size="small"
                                onChange={(newValue) => { isset(props.onChange) ? props.onChange(newValue) : properties.value = dateToString(newValue, 'en', true) }}
                            />
                        }
                        {
                            !isset(props.onChange) &&
                            <DatePicker disabled={props.isDisabled}
                                className='form-control'
                                // value={dayjs(properties.value)}
                                defaultValue={dayjs(properties.value)}
                                sx={{ width: '100%', padding: 0 }}
                                id={properties.name}
                                label={properties.label}
                                format="DD/MM/YYYY"
                                color='primary'
                                size="small"
                                onChange={(newValue) => { isset(props.onChange) ? props.onChange(newValue) : properties.value = dateToString(newValue, 'en', true) }}
                            />
                        }
                    </LocalizationProvider>
                </Grid>;
            break;


        case 'datetime':
            result =
                <Grid item xs={12} md={parseInt(properties.columns)} sx={{ marginTop: 2 }} key={properties.name + '_grid'}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            disabled={props.isDisabled}
                            className='form-control'
                            defaultValue={dayjs(properties.value)}
                            sx={{ width: '100%', padding: 0 }}
                            id={properties.name}
                            label={properties.label}
                            format={isset(props.format) ? props.format : "DD/MM/YYYY HH:mm:ss"}
                            color='primary'
                            size="small"
                            onChange={(newValue) => { isset(props.onChange) ? props.onChange(newValue) : properties.value = dateToString(newValue) }}
                        />
                    </LocalizationProvider>
                </Grid>
            break;


        case 'time':
            result =
                <Grid item xs={12} md={parseInt(properties.columns)} sx={{ marginTop: 2 }} key={properties.name + '_grid'}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileTimePicker
                            defaultValue={dayjs('1970-01-01 ' + properties.value)}
                            format="HH:mm"
                            ampm={false}
                            disabled={props.isDisabled}
                            className='form-control'
                            sx={{ width: '100%', padding: 0 }}
                            id={properties.name}
                            label={properties.label}
                            color='primary'
                            size="small"
                            onChange={(newValue) => { isset(props.onChange) ? props.onChange(newValue) : properties.value = dateToString(newValue) }}
                        />
                    </LocalizationProvider>
                </Grid>
            break;
        case 'file':
            result =
                <Grid item xs={12} md={parseInt(properties.columns)} sx={{ marginTop: 2 }} key={properties.name + '_grid'}>

                    <input

                        file="true"
                        className='form-control p-2'
                        accept={isset(properties.accept) ? properties.accept : "*"}
                        type="file"
                        multiple={false}
                        onChange={onFileChange}
                        label={properties.label}
                        defaultValue={properties.value}
                        id={properties.name}
                        disabled={props.isDisabled}
                    />
                </Grid>
            break;


        case 'select':
            result = <Selection props={properties} isDisabled={props.isDisabled} key={properties.name} />;
            break;

        default:
            result = '';
            break;
    }

    return (result);

}

export default PType;