import React from 'react';
import Box from '@mui/material/Box';
import { Button, Grid, Popover, Typography } from '@mui/material';
import { LightMode, DarkMode } from '@mui/icons-material';
import { getCookie } from '../../../Service/standard';




const Theme = (props) => {
  const [open, setOpen] = React.useState(props.popoverOpen);

  let color = getCookie('theme').length > 0 ? JSON.parse(getCookie('theme')).color : 'blue-type';
  const [green, setGreen] = React.useState((color == 'green-type') ? 'button-theme green p-0 selected' : 'button-theme green p-0');
  const [blue, setBlue] = React.useState((color == 'blue-type') ? 'button-theme blue p-0 selected' : 'button-theme blue p-0');
  const [pink, setPink] = React.useState((color == 'pink-type') ? 'button-theme pink p-0 selected' : 'button-theme pink p-0');
  const [red, setRed] = React.useState((color == 'red-type') ? 'button-theme red p-0 selected' : 'button-theme red p-0');
  const [btns, setBtns] = React.useState({});


  const modal = open ? 'simple-Popover' : undefined;
  const handleSvgClick = (event) => {
    event.stopPropagation();
    document.getElementById('theme').click();
  };

  const colorClick = (event) => {
    switch (event.target.id) {
      case 'green-type':
        setGreen('button-theme green p-0 selected');
        setBlue('button-theme blue p-0');
        setPink('button-theme pink p-0');
        setRed('button-theme red p-0');
        break;

      case 'blue-type':
        setGreen('button-theme green p-0');
        setBlue('button-theme blue p-0 selected');
        setPink('button-theme pink p-0');
        setRed('button-theme red p-0');
        break;

      case 'pink-type':
        setGreen('button-theme green p-0');
        setBlue('button-theme blue p-0');
        setPink('button-theme pink p-0 selected');
        setRed('button-theme red p-0');
        break;

      case 'red-type':
        setGreen('button-theme green p-0');
        setBlue('button-theme blue p-0');
        setPink('button-theme pink p-0');
        setRed('button-theme red p-0 selected');
        break;

      default:
        break;
    }
    event.stopPropagation();
    props.onChangeTheme(event);
  };
  const handleButtonClick = (event) => {
    event.stopPropagation();
    props.onChangeTheme();
  };
  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };
  React.useEffect(() => {
    setOpen(props.popoverOpen);
  }, [props.popoverOpen]);



  React.useEffect(() => {
    setBtns(<Grid container>
      <Grid item xl={6} sx={{ paddingRight: '2px' }}>
        <Button id='blue-type' onClick={colorClick} className={blue} sx={{ width: '15px', height: '50px', fontSize: '10px' }}><span id='blue-type'>BLU</span></Button>
      </Grid>
      <Grid item xl={6} sx={{ paddingRight: '2px' }}>
        <Button id='green-type' onClick={colorClick} className={green} sx={{ width: '15px', height: '50px', fontSize: '10px' }}><span id='green-type'>VERDE</span></Button>
      </Grid>
      <Grid item xl={6} sx={{ paddingRight: '2px' }}>
        <Button id='pink-type' onClick={colorClick} className={pink} sx={{ width: '15px', height: '50px', fontSize: '10px' }}><span id='pink-type'>ROSA</span></Button>
      </Grid>
      <Grid item xl={6} >
        <Button id='red-type' onClick={colorClick} className={red} sx={{ width: '15px', height: '50px', fontSize: '10px' }}><span id='red-type'>ROSSO</span></Button>
      </Grid>
    </Grid>);

  }, [green, blue, red, pink]);




  return (
    <React.Fragment>
      <Popover
        className=''
        id={modal}
        open={open}
        anchorEl={props.currentTarget}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography
          sx={{ padding: '4px', width: '300px' }}
          className="popoverFilters"
        >
          <Box component={'div'} sx={{ marginTop: '10px', color: '#ffffff' }}>
            {btns}
            <Box component={'div'} sx={{ textAlign: 'center', marginTop: '10px' }}>
              <Button id='theme' onClick={handleButtonClick} className='' sx={{ marginTop: 2 }} >
                {props.theme.palette.class == 'dark-version' && <LightMode onClick={handleSvgClick} color="primary" sx={{ cursor: 'pointer' }} />}
                {props.theme.palette.class == 'light-version' && <DarkMode onClick={handleSvgClick} color="primary" sx={{ cursor: 'pointer' }} />}
              </Button>
            </Box>
          </Box>
        </Typography>
      </Popover>
    </React.Fragment>
  )

}

export default Theme;