import { Grid, Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import React from 'react';
import { postRest } from "../../Service/callRest";
import PType from "../includes/PType/ptype";
import { IsNumeric } from '../../Service/standard';
import Swal from 'sweetalert2';

const Settings = (props) => {

    const [settings, setsettings] = useState([]);

    const save = async () => {
        props.pageLoader(true);
        const data = [];
        for (const iterator of settings) {
            let values = iterator.props.children.props.children.props.children.props.children[1].props.children; //[0].props;
            for (let index = 0; index < values.length; index++) {
                const element = values[index].props.props;
                data.push(element);
                
            }
        }
        const params = {
          params: data
        };
        const res = await postRest(props, 'controller/settings', params);
        props.pageLoader(false);
        if (IsNumeric(res)) {
        Swal.fire({
            title: 'Impostazioni Salvate',
            icon: 'success',
            });
        } else {
          Swal.fire({
            title: 'Impossibile Salvare',
            html: res,
            icon: 'warning',
          });
        }
    
      }

    const getSettings = async () => {
        props.pageLoader(true);
        const res = await postRest(props, 'controller/settings');
        const tmp = [];

        for (const iterator of res) {

            let options = [];
            for (const setting of iterator.settings) {
                options.push(
                    <PType key={setting.name}
                        props={{
                            type: setting.type,
                            name: setting.name, 
                            value: setting.value,
                            label: setting.label,
                            columns: setting.columns,
                        }}
                        isDisabled={false}
                    />
                )
            }

            tmp.push(
                <React.Fragment key={iterator.section}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box component={'div'} className='card my-4 z-index-0' sx={{border: 1, borderColor: 'rgba(0, 0, 0, 0.1)'}}>
                                <Box component={'div'} className='card-header p-0 position-relative mt-n4 mx-3 z-index-2'>
                                    <Box component={'h3'} className='text-capitalize ps-3' sx={{color: props.theme.palette.primary.main }} >{iterator.section}</Box>
                                </Box>
                                <Box component={'div'} className='row box m-4 mt-2 text-center'>
                                    {options}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </React.Fragment>

            )
        }
        setsettings(tmp);
        props.pageLoader(false);
    }

    useEffect(() => { getSettings() }, []);


    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Box component={'div'} className='card my-4'>
                        <Box component={'div'} className='card-header p-0 position-relative mt-n4 mx-3 z-index-2'>
                            <Box component={'div'} className='bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3'>
                                <Box component={'h6'} className='text-white text-capitalize ps-3 text-center fs-6'>Impostazioni</Box>
                            </Box>
                        </Box>
                        <Box component={'div'} className='row box m-4'>
                            {settings}
                            <Button
                                onClick={save}
                                className={"btn btn-success w-100"}
                                sx={{ marginTop: 5 }}
                            >Salva</Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>

    );
}

export default Settings;