import { Box } from '@mui/system';
import { isset } from '../../../Service/standard';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { Icon } from '@mui/material';
import * as icons from '@mui/icons-material';


const Alerts = (props) => {
  const navigate = useNavigate();
  const [item, setItem] = React.useState([]);

  const searcIcons = {
    'danger': 'Warning',
    'warning': 'Error',
    'info': 'Info'
  };
  

  const getAlerts = () => {
    const arr = [];
    if (isset(props.alerts.category)) {
      for (const categoryKey in props.alerts.category) {
        const category = props.alerts.category[categoryKey];

        for (const type in category) {
          const msgs = [];
          const value = category[type];
          let myClass = " bg-gradient-" + type;
          for (const key in value) {
            const val = value[key];
            if(categoryKey.includes('Welcome')){
              msgs.push(
                  <Box component={'h5'} className="alert-link text-white text-decoration-none" dangerouslySetInnerHTML={ { __html: val.message}}></Box>
              )

            }else{

              msgs.push(
                <Box component={'ul'} key={key} className="ms-md-5 text-start text-white">
                  <Box component={'li'}><Box component={'h5'} sx={{cursor:'pointer'}} onClick={() => { navigate(val.link)}} className="alert-link text-white text-decoration-none" dangerouslySetInnerHTML={ { __html: val.message}}></Box></Box>
                </Box>
              )
            }
          }
          arr.push(
            <Box component={'div'} className={"mt-5"} key={categoryKey+type} id={categoryKey+type}>
              <Box component={'div'} className="col-xl-12 col-sm-12 mb-xl-0 mb-4">
                <Box component={'div'} className={myClass + " card"}>
                  <Box component={'div'} className="card-header p-3 pt-2">
                    <Box component={'div'} className={myClass + " icon icon-lg icon-shape shadow-dark text-center border-radius-xl mt-n4 position-absolute text-white"} sx={{ padding: '13px' }}>
                      <Icon component={icons[searcIcons[type]]} sx={{ fontSize: '35px' }} />
                    </Box>
                    <Box component={'div'} className="text-center pt-1">
                      <h3 className="mb-0 text-capitalize text-white"> {categoryKey} </h3>
                      <Box component={'div'} sx={{ paddingTop:'1%' }}>
                        {msgs}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        }
      }
    }
    setItem(arr);
  }
  React.useEffect(() => {
    getAlerts();
  }, []);
  return (
    <React.Fragment>
      {item}
    </React.Fragment>

  );
}

export default Alerts;