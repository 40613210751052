import { useNavigate, useParams } from 'react-router-dom';
import back from './../../medias/background-login.webp';
import { Box, Button, Grid, TextField } from '@mui/material';
import { postRest } from '../../Service/callRest';
import { useEffect, useState } from 'react';
import { setCookie } from '../../Service/standard';

const OAuth = (props) => {

    const {_token} = useParams();
    const [img, setImg] = useState('');
    const [code, setCode] = useState('');

    const navigate = useNavigate();


    document.addEventListener('keydown', (event) => {
        if (event.keyCode === 123 && process.env.NODE_ENV != 'development') {
            event.preventDefault();
        }
    })

    const createQr = async () => {
        const res = await postRest(props, 'controller/oauth', {getQr: 1, token: _token});
        if(res.status === true){
            setImg(res.img);
        }
    } 


  
    const codeHandler = (event) => {
        setCode(event.target.value);
    }



    const login = async (event) => {
        props.pageLoader(true);
        const params = {
            token: _token,
            code: code,
        };
    
        event.preventDefault();
        const response = await postRest(props,  `controller/oauth`, params);
    
        if(response.status !== false){
          setCookie('code', response.token, 1);
          props.onLogin(true);
          navigate('/');
        }
    
        props.pageLoader(false);
    
      }

    useEffect(() => {createQr();}, [_token]);


    return (
        <Box component={'main'} onLoad={createQr} className='main-content' onContextMenu={(event) => { event.preventDefault() }}>
            <Box component={'div'} className="page-header align-items-start min-vh-100" style={{ backgroundImage: `url(${back})` }}>
                <Box component={'div'} className="container my-auto">
                    <Box component={'span'} className="mask bg-gradient-dark opacity-6" />
                    <Grid container>
                        <Grid item xs={12} lg={4} md={8} className="mx-auto login">
                            <Box component={'div'} className="card">
                                <Box component={'div'} className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                                    <Box component={'div'} className="bg-gradient-primary shadow-primary border-radius-lg py-3 pe-1">
                                        <Box component={'h4'} className="text-white font-weight-bolder text-center mt-2 mb-0">OAuth</Box>
                                    </Box>
                                </Box>
                                <Box component={'form'} className="card-body">
                                <Box component={'div'} className="text-center">
                                    <img src={img} className='mb-4' />
                                </Box>
                                <div className="col-12 mt-4">
                                    <ol>
                                        <Box component={'li'} sx={{ display: "list-item" }}>Scaricare una applicazione tra <b>Google Authenticator</b> e <b>Microsoft Authenticator</b></Box>
                                        <Box component={'li'} sx={{ display: "list-item" }}>Scansiona il <b>Codice QR</b> dal tuo device</Box>
                                        <Box component={'li'} sx={{ display: "list-item" }}>Inserire il codice a 6 cifre</Box>
                                    </ol>
                                </div>
                                    <Grid container sx={{ spacing: 1 }}>
                                        <Grid item xs={12} sx={{ padding: 1 }}>
                                            <TextField
                                                sx={{ width: '100%', border: 'solid 1px', borderRadius: '10px' }}
                                                id="code"
                                                placeholder="Codice"
                                                color='primary'
                                                type="text"
                                                size="small"
                                                defaultValue={code}
                                                onChange={codeHandler}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sx={{ padding: 1 }}>
                                            <Button onClick={login} type="submit" className="btn bg-gradient-primary w-100 my-4 mb-2">Sign in</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}

export default OAuth;