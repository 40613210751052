import axios from "axios"
import { getCookie, isset } from "./standard";

export const postRest = async (props, url, data, headers = { 'content-type': 'application/x-www-form-urlencoded' }) => {
  const domain = (process.env.NODE_ENV == 'development')  ? process.env.REACT_APP_DEV_URL : process.env.REACT_APP_PROD_URL;
  try {

    headers['Authorization'] = getCookie('code');
    const request = await axios({

      headers: headers,
      method: 'post',
      url: domain + url,
      data: data

    });

    if(isset(request.headers['content-disposition']) && request.headers['content-disposition'].split(";")[0] == 'attachment'){

      const contentDispositionHeader = request.headers['content-disposition'];
      const match = contentDispositionHeader.match(/filename="(.+)"/);
      const fileName = match ? match[1] : 'file'; // Imposta il nome del file di output
      const urlDownload = window.URL.createObjectURL(new Blob([request.data]));
      const link = document.createElement('a');
      link.href = urlDownload;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      request.data = 1;
    }
    const response = request.data;
    return response;
  } catch (error) {
    if (error.response.status === 403) {
      props.onLogout(false);
    } else {
      return error.response.status;
    }
  }
}


export const downloadAttach = async (props, url, data) => {
  const domain = (process.env.NODE_ENV == 'development')  ? process.env.REACT_APP_DEV_URL : process.env.REACT_APP_PROD_URL;
  try {

    const headers = {
      'Authorization': getCookie('code'),
      'content-type': 'application/x-www-form-urlencoded'
    };

    const request = await axios({

      headers: headers,
      method: 'post',
      url: domain + url,
      data: data,
      responseType: 'blob' 

    });

    const contentDispositionHeader = request.headers['content-disposition'];
    const match = contentDispositionHeader.match(/filename="(.+)"/);
    const fileName = match ? match[1] : 'file'; // Imposta il nome del file di output
    const urlDownload = window.URL.createObjectURL(new Blob([request.data]));
    const link = document.createElement('a');
    link.href = urlDownload;
    link.setAttribute('download', `${fileName}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);


  } catch (error) {
    if (error.response.status === 403) {
      props.onLogout(false);
    } else {
      return error.response.status;
    }
  }
}