import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import BasicTable from '../BasicTable/basitable';
import OptionButton from '../OptionButton/optionbutton';

const Section = (props) => {

    const val = props.val;
    const [isCollapsed, setCollapsed] = useState(val.collapsed);

    const onClickEyeHandler = () => {
        setCollapsed(!isCollapsed);
    }


    return (
        <Grid item xs={12} md={parseInt(val.col)}>
            <Box component={'div'} className='card my-4 p-0'>
                <Box component={'div'} className='card-header p-0 position-relative mt-n4 mx-3 z-index-2'>
                    <Box component={'div'} className='bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3'>
                        <Box component={'h6'} className='text-white text-capitalize ps-3 text-center fs-6'>
                            <Box component={'div'} className='cursor-pointer' onClick={onClickEyeHandler}>{isCollapsed ? <VisibilityIcon /> : <VisibilityOffIcon />}</Box>
                            {val.title}
                        </Box>
                    </Box>
                </Box>
                <Box component={'div'} sx={{ paddingLeft: 2, paddingRight: 2 }}>
                    <Box component={'div'} className='buttons' sx={{paddingBottom:2}}>
                        <OptionButton props={val} thisObj={props.thisObj} thisId={props.thisId}/>
                    </Box>
                    <Box component={'div'} className='table-responsive p-0'>
                      {!isCollapsed && <BasicTable headers={val.headers} items={val.items}></BasicTable>}
                    </Box>
                </Box>
            </Box>
        </Grid>
    )
}

export default Section;