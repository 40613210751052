import { Box, Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Chart from 'chart.js/auto';
import { isset } from "../../../Service/standard";



const BasicCharts = (props) => {
    const [type, setType] = useState('');
    const [columns, setColumns] = useState('');
    const [title, setTitle] = useState('');
    const [datasets, setDatasets] = useState([]);
    const chartRef = useRef(null);
    let ctx = '';
    
      const createChart = (ctx) => {
        let chartInstance = new Chart(ctx, {
          type: type,
          data: {
            // labels: datasets.map((item) => { for (const key in item.data) { return (key); }}),
            backgroundColor: props.theme.palette.primary.main,
            datasets: datasets.map((item) => {
                if(isset(item.backgroundColor) === false){
                    if(props.theme.palette.primary.main == '#fff'){
                        if(isset(item.backgroundPadding)){
                            item.backgroundColor = 'rgba(255, 255, 255, ' + item.backgroundPadding + ')';
                        }else{
                            item.backgroundColor = 'rgba(255, 255, 255, 0.1)';
                        }
                    }else{
                        if(isset(item.backgroundPadding)){
                            item.backgroundColor = 'rgba(0, 0, 0, ' + item.backgroundPadding + ')';
                        }else{
                            item.backgroundColor = 'rgba(0, 0, 0, 0.1)';
                        }
                    }
                } 
                if(isset(item.borderColor) === false){
                    item.borderColor = '#bcbcbc';
                } 
                item.borderWidth = 1; 
                item.tension = 0.1;
                item.fill = true; 
                return item;
            }),
          },
          options: {
            plugins: {
                legend: {
                    labels: {
                        color: props.theme.palette.primary.main,
                    }
                }
            },
            scales: {
                y: {
                    ticks: {
                        color: props.theme.palette.primary.main
                    }
                },
                x: {
                    ticks: {
                        color: props.theme.palette.primary.main
                    }
                }
            }
        }
        });

        return chartInstance;
      };
      
 

    useEffect(() => {
        ctx = chartRef.current.getContext('2d');
        setType(props.type);
        setColumns(props.columns);
        setTitle(props.title);
        setDatasets(props.datasets);
        let chart = createChart(ctx);
        return () => {
            chart.destroy()
          }
        
    }, [props]);



    return (
        <Grid item xs={12} md={parseInt(columns)} sx={{ marginTop: 2 }} className="mt-5" >
            <Box component={'div'} className="card z-index-2 ">
                <Box component={'div'} className="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
                    <Box component={'div'} className="bg-gradient shadow-primary border-radius-lg py-3 pe-1">
                        <Box component={'div'} className="chart">
                            <canvas ref={chartRef} />
                        </Box>
                    </Box>
                </Box>
                <Box component={'div'} className="card-body">
                    <Box component={'h6'} className="mb-0 ">{title}</Box>
                </Box>
            </Box>
        </Grid>
    );
}

export default BasicCharts;