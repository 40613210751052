import { Box } from "@mui/system";
import Alerts from "../includes/Alerts/alerts";
import React from 'react';
import { postRest } from "../../Service/callRest";
import BasicTable from "../includes/BasicTable/basitable";
import BasicCharts from "../includes/BasicCharts/BasicCharts";
import { Grid } from "@mui/material";
const DashBoard = (props) => {

    const [alerts, setAlerts] = React.useState('');
    const [name, setName] = React.useState('');
    const [Disponibilita_conti, setDisponibilita_conti] = React.useState('');
    const [charts, setCharts] = React.useState([]);
    
    let loading = true;
    const alertsHandler = async () => {
        if (loading === true) {
            props.pageLoader(true);
            loading = false;
            const response = await postRest(props,   `controller/alerts`);
            setAlerts(<Alerts alerts={response.alerts} />);
            setName(response.name);
            loading = true;
            // props.pageLoader(false);
        }
    };
    const cashHandler = async () => {
            props.pageLoader(true);
            const response = await postRest(props,   `controller/disponibilita_conti`);

            if(response.length > 0){
               
                for (const iterator of response) {
                    iterator['items'] = [iterator['soc'], iterator['conto'], iterator['disponibilita']];
                }

                setDisponibilita_conti(
                    <React.Fragment>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box component={'div'} className='card my-4'>
                                    <Box component={'div'} className='card-header p-0 position-relative mt-n4 mx-3 z-index-2'>
                                        <Box component={'div'} className='bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3'>
                                            <Box component={'h6'} className='text-white text-capitalize ps-3 text-center fs-6'>Disponibilità Conti Bancari</Box>
                                        </Box>
                                    </Box>
                                    <Box component={'div'} className='m-4 row box'>
                                        <Box component={'div'} className='table-responsive p-0'>
                                            <BasicTable 
                                            cash
                                            headers={[
                                                {
                                                    key: 'societa',
                                                    headerName: 'Societa'
                                                },
                                                {
                                                    key: 'conto',
                                                    headerName: 'Conto'
                                                },
                                                {
                                                    key: 'disponibilita',
                                                    headerName: 'Disponibilita'
                                                }
                                            ]}
                                            items={response}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                    
                    );
            } 
            // props.pageLoader(false);
    };

    const chartsHandler = async () => {
        props.pageLoader(true);
        const response = await postRest(props, `controller/charts`);
        const tmpChart = [];
        for (const iterator of response) {
            tmpChart.push(
                <BasicCharts 
                    type={iterator.type} 
                    columns={iterator.columns} 
                    title={iterator.title} 
                    key={iterator.title} 
                    datasets={iterator.datasets} 
                    theme={props.theme} 
                />
            );
        }
        setCharts(tmpChart);
        props.pageLoader(false);
    }
    React.useEffect(() => {
        alertsHandler();
        cashHandler();
        chartsHandler();
      }, []);
    React.useEffect(() => {
        chartsHandler();
      }, [props.theme]);
    return (
        <Box component={'div'}>
            <Box component={'h2'}>{name}</Box>
            <Box component={'div'} className="mt-5 dash">
                {alerts}
            </Box>
            <Box component={'div'} className="mt-5 dash">
                {Disponibilita_conti}
            </Box>
            <Grid container className='row mb-4 mx-1 mt-2'>
                {charts}
            </Grid>
        </Box>
    )
}

export default DashBoard;