import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { postRest } from './../../Service/callRest';
import { setCookie } from './../../Service/standard';
import { useNavigate } from 'react-router-dom';

import './../../bootstrap.css';
import './../../login.css';


import back from './../../medias/background-login.webp';

const RecuperaPassword = (props) => {
  const [email, setEmail] = React.useState('');
  const [checkEmail, setCheckEmail] = React.useState(false);
  const navigate = useNavigate();

  const emailHandler = (event) => {
    setEmail(event.target.value);
  }

  document.addEventListener('keydown', (event) => {
    if(event.keyCode === 123 && process.env.NODE_ENV != 'development'){
    event.preventDefault();
    }
  })

  const loginHandler = async (event) => {
    props.pageLoader(true);
    const params = {
      getPwd:
      {
        'email': email,
      }
    };

    event.preventDefault();


    const response = await postRest(props,  `controller/login`, params);

    if(response !== false){
      setCheckEmail(true);
      navigate('/signup');
    }

    props.pageLoader(false);
    props.onLogin(true);

  }
  
  props.pageLoader(false);
  return (
    <Box component={'main'} className='main-content' onContextMenu={(event) => { event.preventDefault() }}>
      <Box component={'div'} className="page-header align-items-start min-vh-100" style={{ backgroundImage: `url(${back})` }}>
        <Box component={'div'} className="container my-auto text-center">
          <Box component={'span'} className="mask bg-gradient-dark opacity-6" />
          <Grid container>
            <Grid item xs={12} lg={4} md={8} className="mx-auto">
              <Box component={'div'} className="container-login" /*onContextMenu={(event) => { event.preventDefault() }}*/>
                <i></i>
                <i></i>
                <i></i>
                <Box component={'div'} className="login">
                  <Box component={'h2'}>Recupera Password</Box>


                  <div className="inputBx">
                    <input
                      id="email"
                      placeholder="Email"
                      type="email"
                      defaultValue={email}
                      onChange={emailHandler}
                    />
                  </div>
                  <div className="inputBx">
                    <input onClick={loginHandler} onTouchStart={loginHandler} type="submit" value='Recupera' />
                  </div>
                  {checkEmail &&<Box 
                  component={'p'} sx={{color: 'white'}}
                  >
                    Email inviata con successo
                  </Box>}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
    
  )
}

export default RecuperaPassword;