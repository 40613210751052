import Box from '@mui/material/Box';
import React from 'react';

import { Menu, ColorLens } from '@mui/icons-material';
import { Button } from '@mui/material';
import Theme from './theme';

const NavBar = (props) => {
    const [popoverOpen, setPopoverOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handlePopoverClick = (event) => {
        setAnchorEl(event.currentTarget);
        setPopoverOpen(true);
      };
    const handlePopoverClose = (event) => {
        setPopoverOpen(!popoverOpen);
      };
    return (
        <Box component={'nav'} className='navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl'>
            <Box component={'div'} className='container-fluid py-1 px-3'>
                <Box component={'nav'}>
                    <br/>
                    <Box component={'h1'} className='font-weight-bolder mb-0' sx={{fontSize: 30}}>{props.name}</Box>
                </Box>
                <Box component={'div'} className='collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4'>
                    <Box component={'ul'} className='ms-md-auto d-flex align-items-center navbar-nav justify-content-end'>
                        <li className="nav-item pe-3 d-xl-none d-flex align-items-center">
                            <div  className="nav-link text-body p-0" id="iconNavbarSidenav">  
                                <Box className='dashboard-icon' sx={{marginTop:2}} component={'div'} onClick={props.showMenu}><Menu  color="primary" sx={{cursor: 'pointer'}}/></Box>
                            </div>
                        </li>
                        <li className="nav-item pe-3 d-flex align-items-center">
                            <div className="nav-link text-body p-0">  
                                <Button sx={{marginTop:2}} onClick={handlePopoverClick}><ColorLens color="primary" sx={{cursor: 'pointer'}} /></Button>
                            </div>
                        </li>
                        <Theme currentTarget={anchorEl} popoverOpen={popoverOpen} theme={props.theme} onChangeTheme={props.onChangeTheme} onClose={handlePopoverClose}/> 
                    </Box>
                </Box>
            </Box>
        </Box>
    )/*props.onChangeTheme*/
}
export default NavBar;