import { Box } from '@mui/system';
import React, { useState } from 'react';
import { Icon } from '@mui/material';
import * as icons from '@mui/icons-material';
import { isset } from '../../../Service/standard';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const GenericAlerts = (props) => {

  const searcIcons = {
    'danger': 'Warning',
    'warning': 'Error',
    'info': 'Info'
  };

  let myClass = " bg-gradient-" + props.type;

  const msg = [];

  const [closing] = useState((isset(props.closing)) ? true : false);
  const [close, setClose] = useState(true);

  for (const iterator of props.msg) {
    msg.push(
      iterator, <Box component={'br'} />
    );
  }

  const onClickEyeHandler = () => {
    setClose(!close);
  }

  return (
    <React.Fragment>
      <Box component={'div'} className="mt-5" key={props.category+props.type} id={props.category+props.type} sx={{marginBottom:'1%'}}>
              <Box component={'div'} className="col-xl-12 col-sm-12 mb-xl-0 mb-4">
                <Box component={'div'} className={myClass + " card"}>
                  <Box component={'div'} className="card-header p-3 pt-2">
                    <Box component={'div'} className="text-center pt-1">
                      {
                        closing &&
                        <Box component={'div'} className='cursor-pointer' onClick={onClickEyeHandler}>{close ? <VisibilityIcon /> : <VisibilityOffIcon />}</Box>
                      }
                      <h3 className="mb-0 text-capitalize text-white"> {props.category} </h3>
                      {
                        !close &&
                        <Box component={'div'} sx={{ paddingTop:'1%' }}>
                          {msg}
                        </Box>
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
    </React.Fragment>

  );
}

export default GenericAlerts;