import React, { useState } from 'react';
import { postRest } from '../../Service/callRest';
import { getCookie } from '../../Service/standard';
import BasicTable from '../includes/BasicTable/basitable';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';


const MyAttachments = (props) => {

    const [attachments, setAttachments] = useState([]);

    const headers = [
        { key: 'name', headerName: 'Nome File' },
        { key: 'type', headerName: 'Tipologia' },
        { key: 'download', headerName: 'Scarica' },
    ];

    const getAttachments = async () => {
        props.pageLoader(true);
        const params = {
            session: getCookie('code')
        };

        const res = await postRest(props,   'controller/myAttachments', params);
        for (const iterator of res) {
            iterator['function'] = 'download';
            iterator['params'] = iterator['id'];
            iterator['items'] = [iterator['name'], iterator['type'], <CloudDownloadIcon />]
        }
        setAttachments(res);
        props.pageLoader(false);
    }

    React.useEffect(() => {
        getAttachments();
    }, []);

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Box component={'div'} className='card my-4'>
                        <Box component={'div'} className='card-header p-0 position-relative mt-n4 mx-3 z-index-2'>
                            <Box component={'div'} className='bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3'>
                                <Box component={'h6'} className='text-white text-capitalize ps-3 text-center fs-6'>I Miei Allegati</Box>
                            </Box>
                        </Box>
                        <Box component={'div'} className='m-4 row box'>
                            <Box component={'div'} className='table-responsive p-0'>
                                <BasicTable
                                    headers={headers}
                                    items={attachments}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    )

}

export default MyAttachments;