import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { postRest } from "../../Service/callRest";
import { dateToString, getCookie, IsNumeric, isset } from "../../Service/standard";
import PType from "../includes/PType/ptype";
import Swal from 'sweetalert2';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';


const Booking = (props) => {

    const [fields, setFields] = useState([]);
    const [date] = useState((new Date().getHours() < 19) ? new Date().setDate(new Date().getDate() + 1) : new Date().setDate(new Date().getDate() + 2));

    const [cod_commessa, setCod_commessa] = useState('');

    const navigate = useNavigate();

    const save = async () => {
        props.pageLoader(true);
        const obj = [];
        obj.push(
            { id: 'cod_commessa', value: cod_commessa },
            { id: 'status', value: '0' },
        );
        for (const iterator of fields) {
            if(isset(iterator.props.props)){
                obj.push(
                    { 
                        id: iterator.props.props.name, 
                        value: (iterator.props.props.name == 'data') ?  dateToString(iterator.props.props.value, 'en', true) : iterator.props.props.value }
                );
            }
        }
        const params = {
            session: getCookie('code'),
            data: obj
        };

        const res = await postRest(props,   'controller/booking', params);
        props.pageLoader(false);
        if (IsNumeric(res)){
            navigate('/tableList/Bookings');
        }else{
            Swal.fire({
                title: 'Impossibile Salvare',
                html: res,
                icon: 'warning',
            });
        }
    }


    const get = async () => {
        props.pageLoader(true);
        const params = {
            session: getCookie('code')
        };
        const tmp = [];

        const res = await postRest(props,   'controller/booking', params);
        setCod_commessa(res.cod_commessa);

        if(isset(res.cod_commessa)){
            tmp.push(
                <input type={'hidden'} id='cod_commessa' key={'cod_commessa'} value={cod_commessa} />,
                <PType
                    props={{
                        type: 'date',
                        name: 'data',
                        label: 'Data',
                        value: date,
                        columns: (res.can_smart == 1) ? '3' : '4'
                    }}
                    isDisabled={false}
                />,
                <PType
                    props={{
                        type: 'time',
                        name: 'orario_inizio',
                        label: 'Dalle Ore',
                        value: '09:00',
                        columns: (res.can_smart == 1) ? '3' : '4'
                    }}
                    isDisabled={false}
                />,
                <PType
                    props={{
                        type: 'time',
                        name: 'orario_fine',
                        label: 'Alle Ore',
                        value: '13:00',
                        columns: (res.can_smart == 1) ? '3' : '4'
                    }}
                    isDisabled={false}
                />
            );

            if(res.can_smart == 1){
                tmp.push(
                    <PType
                        props={{
                            type: 'select',
                            name: 'is_smartworking',
                            label: 'Prenotazione SmartWorking',
                            value: "0",
                            columns: (res.can_smart == 1) ? '3' : '4',
                            options: [
                                {value: '0', label: 'NO'},
                                {value: '1', label: 'SI'},
                            ]
                        }}
                        isDisabled={false}
                    />
                );
            }
        }else{
            tmp.push(
                <Box component={'h6'} className='text-capitalize ps-3 text-center fs-6'>Non sei associato a nessuna commessa in questo periodo</Box>
            );
        }
        
        setFields(tmp);

        props.pageLoader(false);
    }

    useEffect(() => {
        get();
    }, []);
    
    const backLink = () => {
        navigate( -1 );
    }

    return(
        <React.Fragment>
            <Grid container>
                <Box component={'div'} className='cursor-pointer mb-3'>
                    <Button onClick={backLink} color="primary"> <ArrowCircleLeftIcon/>&nbsp; Torna Indietro </Button> 
                </Box>
                <Grid item xs={12}>
                    <Box component={'div'} className='card my-4'>
                        <Box component={'div'} className='card-header p-0 position-relative mt-n4 mx-3 z-index-2'>
                            <Box component={'div'} className='bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3'>
                                <Box component={'h6'} className='text-white text-capitalize ps-3 text-center fs-6'>Prenotazioni</Box>
                            </Box>
                        </Box>
                        <Box component={'div'} className='m-4 row box'>
                            {fields}
                            <Button
                                onClick={save}
                                onTouchStart={save}
                                className={"btn btn-success w-100"}
                                sx={{marginTop: 5}}
                            >Salva</Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default Booking;