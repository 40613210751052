import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { isset } from "../../../Service/standard";
import { Icon } from '@mui/material';
import * as icons from '@mui/icons-material';

const TableButton = (props) => {
  const navigate = useNavigate();
  const propss = props;

  const addHandler = () => {
    navigate('/edit/' + propss.obj + '/0');
  }

  const handleRemoveAdvancedFilters = () => {
    let urlTo = ""
    if (isset(propss.qExtra)) {
      urlTo = '/tableList/' + propss.obj + '/' + propss.qExtra + '/' + propss.where;
    } else {
      urlTo = '/tableList/' + propss.obj;
    }
    navigate(urlTo);
  };

  const types = {

    hidden: {
      label: '',
      type: 'success',
      path: '',
      display: 'none'
    },

    Add: {
      label: 'Aggiungi',
      icon: 'Add',
      type: 'btn-success',
      method: addHandler,
      display: ''
    },

    RemoveFilters: {
      label: 'Rimuovi Filtri Avanzati',
      icon: 'Delete',
      type: 'btn-danger',
      method: handleRemoveAdvancedFilters,
      display: ''
    },

    ImportRinnovi: {
      label: 'Importa Rinnovi',
      icon: 'Update',
      type: 'btn-purple',
      method: () => { navigate('/edit/ProrogheContrattuali/0'); },
      display: ''
    },

    RaggruppaCosti: {
      label: 'Export Raggruppato',
      icon: 'Diversity2',
      type: 'btn-purple',
      method: () => { navigate('/edit/RaggruppaCostiEmployees/0'); },
      display: ''
    },
  };
  const [property] = useState(isset(types[propss.buttons]) ? types[propss.buttons] : types['hidden']);
  return (

    <Button
      onClick={property.method}
      className={"btn btn-sm rounded-pill " + property.type}
      size="small"
      sx={{display: property.display}}
    >
      <Box component={'span'} className="MuiButton-startIcon MuiButton-iconSizeSmall css-y6rp3m-MuiButton-startIcon">

        <Icon component={icons[property.icon]}  />
      </Box>
      {' ' + property.label}
    </Button>

  )
}

export default TableButton;