import Box from '@mui/material/Box';
import React from 'react';
import { postRest } from '../../Service/callRest';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { AppBar, Tab, Tabs } from '@mui/material';
import { useTheme } from '@emotion/react';
import TableSingle from './TableSingle';
import { isset } from '../../Service/standard';


const TableList = (props) => {

  const { _obj } = useParams();
  const { _filters } = useParams();
  const { _qExtra } = useParams();
  const { _where } = useParams();
  let tmpTabs = [];
  const theme = useTheme();
  let tmpTabsContent = [];

  const [tabs, setTabs] = React.useState([]);
  const [tabsContent, setTabsContent] = React.useState([]);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    getTable(_obj.split(',')[newValue],newValue, false);
  };


  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index } = props;

    return (
      <Box component={'div'}
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
      >
        {value === index && children}
      </Box>
    );
  }

  const getTable = async (obj, i, onlyName = true) => {
    if (onlyName === true || (obj === tabsContent[i]['key'] && onlyName === false && tabsContent[i]['props']['data'].data.length === 0)) {
      
      if (onlyName === false) {
        props.pageLoader(true);
      }
      let params = {
        Table: obj
      };
      if (i > 0 && onlyName === true) {
        params = {
          Table: obj,
          onlyName: 1
        };
      }
  
      if (isset(_qExtra)) {
        params = {
          Table: obj,
          qExtra: _qExtra,
          where: _where
        };
      }
      if (isset(_filters)) {
        params = {
          Table: obj,
          filters: _filters
        };
      }
  
      const res = await postRest(props, 'controller/tableList', params);
      tmpTabs[i] = (<Tab key={i} className='text-white text-capitalize ps-3 text-center fs-6' label={res.objName} {...a11yProps(i)} />);
      tmpTabsContent = tabsContent;
      tmpTabsContent[i] = (
  
        <TableSingle
          key={obj}
          obj={obj}
          filters={_filters}
          qExtra={_qExtra}
          where={_where}
          data={res}
          pageLoader={props.pageLoader}
  
  
        />
      );
      if (onlyName === false) {
        setTabsContent(tmpTabsContent);
        props.pageLoader(false);
      }
    }
  }


  let loading = true;
  const createTable = async () => {
    if (loading) {
      loading = false;
      props.pageLoader(true);
      let i = 0;
      let count = (_obj.includes(',')) ? (_obj.split(',').length) : 1;
      if (count > 1) {
        for (const iterator of _obj.split(',')) {
          if (i < count) {
            await getTable(iterator, i);
          }
          i++;
        }
      } else {
        await getTable(_obj, i);
      }
      setTabs(tmpTabs);
      setTabsContent(tmpTabsContent);
      loading = true;
      props.pageLoader(false);
    }
  };

  React.useEffect(() => {
    setValue(0);
    setTabs([]);
    setTabsContent([]);
    createTable();

  }, [_obj, _filters, _qExtra, _where]);


  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Box component={'div'} className='card my-4'>
            <Box component={'div'} className='card-header p-0 position-relative mt-n4 mx-3 z-index-2'>
              <Box component={'div'} className='bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3'>
                <Box component={'h6'} className='text-white text-capitalize ps-3 text-center fs-6'>
                  <AppBar position="static" sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      centered
                    >
                      {tabs}
                    </Tabs>
                  </AppBar>
                </Box>
              </Box>
            </Box>
            {
              tabsContent.map((item, index) => {
                {
                  return <TabPanel value={value} index={index} dir={theme.direction}>
                    {item}
                  </TabPanel>
                }
              })
            }
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default TableList;