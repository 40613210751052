import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { postRest } from './../../Service/callRest';
import { setCookie } from './../../Service/standard';
import { useNavigate } from 'react-router-dom';

import './../../bootstrap.css';
import './../../login.css';

import back from './../../medias/background-login.webp';
import logo from './../../medias/Comverta_Logo_White.png';

const Signup = (props) => {
  const [email, setEmail] = React.useState('');
  const [pwd, setPwd] = React.useState('');
  const [newPwd, setNewPwd] = React.useState('');
  const [confNewPwd, setConfNewPwd] = React.useState('');
  const [submitStatus, setSubmitStatus] = React.useState(true);
  const [length, setlength] = React.useState('invalid-feedback');
  const [minus, setminus] = React.useState('invalid-feedback');
  const [major, setmajor] = React.useState('invalid-feedback');
  const [number, setnumber] = React.useState('invalid-feedback');
  const [chars, setchars] = React.useState('invalid-feedback');
  const [equal, setequal] = React.useState('invalid-feedback');


  const navigate = useNavigate();

  const emailHandler = (event) => {
    setEmail(event.target.value);
  }
  const pwdHandler = (event) => {
    setPwd(event.target.value);
  }
  const newPwdHandler = (event) => {
    setNewPwd(event.target.value);
  }
  const confNewPwdHandler = (event) => {
    setConfNewPwd(event.target.value);
  }
  const checkCase = (string, isMajor = true) => {
    let check = false;
    let chars = ['@', '?', '!', '#', '$', '%'];
    const reg = new RegExp('^[0-9]+$');
    for (let i = 0; i < string.length; i++) {
      let char = string[i];
      if (isMajor) {
        if (char === char.toUpperCase() && !chars.includes(char) && !reg.test(char)) {
          check = true;
        }
      } else {
        if (char === char.toLowerCase() && !chars.includes(char) && !reg.test(char)) {
          check = true;
        }
      }
    }
    return check;
  }

  const checkSpecialChars = (string) => {
    let check = false;
    let chars = ['@', '?', '!', '#', '$', '%'];
    for (let i = 0; i < string.length; i++) {
      let char = string[i];
      if (chars.includes(char)) {
        check = true;
      }
    }
    return check;
  }

  const checkNumbers = (string) => {
    let check = false;
    const reg = new RegExp('^[0-9]+$');
    for (let i = 0; i < string.length; i++) {
      let char = string[i];
      if (reg.test(char)) {
        check = true;
      }
    }
    return check;
  }

  document.addEventListener('keydown', (event) => {
    if (event.keyCode === 123 && process.env.NODE_ENV != 'development') {
      event.preventDefault();
    }
  })

  const check = () => {
    let new_pwd = newPwd;
    let conf_new_pwd = confNewPwd;

    if (new_pwd.length >= 8 && new_pwd.length <= 20) {
      setlength('invalid-feedback');
      setlength('valid-feedback');
    } else {
      setlength('valid-feedback');
      setlength('invalid-feedback');
    }

    if (checkCase(new_pwd)) {
      setmajor('invalid-feedback');
      setmajor('valid-feedback');
    } else {
      setmajor('valid-feedback');
      setmajor('invalid-feedback');
    }

    if (checkCase(new_pwd, false)) {
      setminus('invalid-feedback');
      setminus('valid-feedback');
    } else {
      setminus('valid-feedback');
      setminus('invalid-feedback');
    }

    if (checkSpecialChars(new_pwd)) {
      setchars('invalid-feedback');
      setchars('valid-feedback');
    } else {
      setchars('valid-feedback');
      setchars('invalid-feedback');
    }

    if (new_pwd === conf_new_pwd) {
      setequal('invalid-feedback');
      setequal('valid-feedback');
    } else {
      setequal('valid-feedback');
      setequal('invalid-feedback');
    }

    if (checkNumbers(new_pwd)) {
      setnumber('invalid-feedback');
      setnumber('valid-feedback');
    } else {
      setnumber('valid-feedback');
      setnumber('invalid-feedback');
    }



    if (
      (new_pwd.length >= 8 && new_pwd.length <= 20) &&
      checkCase(new_pwd) &&
      checkCase(new_pwd, false) &&
      checkSpecialChars(new_pwd) &&
      new_pwd === conf_new_pwd &&
      checkNumbers(new_pwd)
    ) {
      setSubmitStatus(false);
    } else {
      setSubmitStatus(true);
    }
  }

  const signupHandler = async (event) => {
    props.pageLoader(true);
    const params = {
      signData:
      {
        'email': email,
        'psw': pwd,
        'new_pwd': newPwd,
        'conf_new_pwd': confNewPwd,
      }
    };


    const response = await postRest(props, `controller/login`, params);

    if (response !== false) {
      setCookie('code', response, 1);
    }

    props.pageLoader(false);
    navigate('/');
    props.onLogin(true);

  }
  props.pageLoader(false);

  React.useEffect(() => {
    check();
  }, [newPwd, confNewPwd, email, newPwd]);
  return (
    <Box component={'main'} className='main-content' onContextMenu={(event) => { event.preventDefault() }}>
      <Box component={'div'} className="page-header align-items-start min-vh-100" style={{ backgroundImage: `url(${back})` }}>
        <Box component={'div'} className="container my-auto text-center">
          <Box component={'span'} className="mask bg-gradient-dark opacity-6" />
          <Grid container>
            <Grid item xs={12} lg={4} md={8} className="mx-auto">
              <Box component={'div'} className="container-signup">
                <Box component={'div'} className="signup">
                  <Box component={'div'}>
                    <img src={`${logo}`} style={{height:80}} alt="main_logo"/>
                  </Box>
                  <Box component={'h2'}>Sign Up</Box>

                  <div className="inputBx">
                    <input
                      id="email"
                      placeholder="Email"
                      type="email"
                      defaultValue={email}
                      onChange={emailHandler}
                    />
                  </div>
                  <div className="inputBx">
                    <input
                      id="pwd"
                      placeholder="Password"
                      type="password"
                      defaultValue={pwd}
                      onChange={pwdHandler}
                    />

                  </div>

                  <div className="inputBx">
                    <input
                      id="new_pwd"
                      placeholder="Nuova Password"
                      type="password"
                      defaultValue={newPwd}
                      onChange={newPwdHandler}
                    />

                  </div>
                  <div className="inputBx">
                    <input
                      id="conf_new_pwd"
                      placeholder="Conferma Nuova Password"
                      type="password"
                      defaultValue={confNewPwd}
                      onChange={confNewPwdHandler}
                    />

                  </div>
                  <div className="inputBx">
                    <input disabled={submitStatus} onClick={signupHandler} onTouchStart={signupHandler} type={((!submitStatus) ? 'submit' : 'button')} value='Sign Up' />
                  </div>
                  <div className="validatePwd">
                    <div className="col-12 mt-4">
                      <ul>
                        <Box component={'li'} className={length} sx={{ display: "list-item" }}>Deve essere compresa fra 8 e 20 caratteri</Box>
                        <Box component={'li'} className={major} sx={{ display: "list-item" }}>Almeno una lettera maiuscola</Box>
                        <Box component={'li'} className={minus} sx={{ display: "list-item" }}>Almeno una lettera minuscola</Box>
                        <Box component={'li'} className={number} sx={{ display: "list-item" }}>Almeno un carattere numerico</Box>
                        <Box component={'li'} className={chars} sx={{ display: "list-item" }}>Almeno un carattere tra i presenti (@, #, !, ?, %, $)</Box>
                        <Box component={'li'} className={equal} sx={{ display: "list-item" }}>Le due password corrispondono</Box>
                      </ul>
                    </div>
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>  
        </Box>
      </Box>
    </Box>
  )
}

export default Signup;