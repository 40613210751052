import { Box, Button, Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useEffect, useState } from "react";
import { dateToString } from "../../Service/standard";
import { isset } from "../../Service/standard";
import ClearIcon from '@mui/icons-material/Clear';
import { postRest } from "../../Service/callRest";
import EventBox from "./eventBox";

const WorkSession = (props) => {

    const [currentDateMonth, setCurrentDateMonth] = useState((new Date()));
    const [calendar, setCalendar] = useState([]);
    const [buttonClass, setButtonClass] = useState('btn btn-success');
    const [buttonText, setButtonText] = useState('Timbra Entrata');
    const [todaySessions, setTodaySessions] = useState([]);

    const [weeks, setWeeks] = useState(() => {
        const date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (new Date().getDay() + 6) % 7);

        let nset = [];
        for (let index = 0; index < 7; index++) {
            nset[index] = dateToString(new Date(date.getFullYear(), date.getMonth(), date.getDate() + index), 'en');
        }

        return nset;
    });


    const [colCalendar, setColCalendar] = useState(12);
    const [colEvents, setColEvents] = useState(0);
    const [displayEvents, setDisplayEvents] = useState('none');
    const [eventCard, setEventCard] = useState(<Box component={'div'} />);


    const preview = () => {
        setCurrentDateMonth(dateToString(new Date(currentDateMonth).setDate(new Date(currentDateMonth).getDate() - 7)));
        closeEvent();
    }
    const next = () => {
        setCurrentDateMonth(dateToString(new Date(currentDateMonth).setDate(new Date(currentDateMonth).getDate() + 7)));
        closeEvent();
    }
    const today = () => {
        setCurrentDateMonth(dateToString(new Date()));
        closeEvent();
    }
    const openEvent = (event) => {
        setColCalendar(7);
        setColEvents(5);
        setDisplayEvents('block');
        setEventCard(
            <Box component={'div'} sx={{ height: '100%' }}>
                <EventBox
                    data={event.currentTarget.id}
                    pageLoader={props.pageLoader}
                    closeEvent={closeEvent}
                    createCalendar={createCalendar}
                />

            </Box>
        )
    }
    const closeEvent = () => {
        setColCalendar(12);
        setColEvents(0);
        setDisplayEvents('none');
    }


    const setWorkSession = async() => {
        props.pageLoader(true);
        await postRest(props, 'controller/workSession', { update: 1 });
        props.pageLoader(false);
        createCalendar();
    }

    useEffect(() => {
        createCalendar();
    }, [currentDateMonth]);



    const createCalendar = async () => {
        props.pageLoader(true);
        let rows = [];
     


        let nset = [];



        const date = new Date(new Date(currentDateMonth).getFullYear(), new Date(currentDateMonth).getMonth(), new Date(currentDateMonth).getDate() - (new Date(currentDateMonth).getDay() + 6) % 7);


        for (let index = 0; index < 7; index++) {
            nset[index] = dateToString(new Date(date.getFullYear(), date.getMonth(), date.getDate() + index), 'en');
        }
        setWeeks(nset);

        const res = await postRest(props, 'controller/workSession', { date_from: nset[0], date_to: nset[6] });
        const tmpSessions = [];

        for (const iterator of res.today) {
            if(iterator.date_to === null){
                tmpSessions.push(dateToString(iterator.date_from, 'it', false) + ' - In Corso');
            }else{
                tmpSessions.push(dateToString(iterator.date_from, 'it', false) + ' - ' + dateToString(iterator.date_to, 'it', false));
            }
        }
        setTodaySessions(tmpSessions);


        if(res.is_attivo === true){
            setButtonClass('btn btn-danger');
            setButtonText('Timbra Uscita');
        }else{
            setButtonClass('btn btn-success');
            setButtonText('Timbra Entrata');
        }

        let key = 0;
        rows.push(
            <TableCell className="text-center align-items-center cursor-pointer calendar" key={'hours'} sx={{ width: '100px' }} /*onClick={openEvent}*/>
                    <Box component={'div'} className="work-hours">06:00</Box>
                    <Box component={'div'} className="work-hours">08:00</Box>
                    <Box component={'div'} className="work-hours">10:00</Box>
                    <Box component={'div'} className="work-hours">12:00</Box>
                    <Box component={'div'} className="work-hours">14:00</Box>
                    <Box component={'div'} className="work-hours">16:00</Box>
                    <Box component={'div'} className="work-hours">18:00</Box>
                    <Box component={'div'} className="work-hours">20:00</Box>
            </TableCell>
        )
        nset.map((item) => {
            let className = "text-center align-items-center cursor-pointer calendar";
            if (item == dateToString(new Date(), 'en', true)) {
                className = "text-center align-items-center cursor-pointer calendar-today";
            }
            let height = '0px';
            let top = '0px';
            if(isset(res.sessions[dateToString(item, 'en', true)])){
                height = res.sessions[dateToString(item, 'en', true)].height;
                top = res.sessions[dateToString(item, 'en', true)].top;
            }
            rows.push(
                <TableCell className={className} sx={{ width: '100px' }} /*onClick={openEvent}*/ >
                    <Box component={'div'} className="turn" sx={{maxHeight: '280px', minHeight: '0px', position: "relative", height: height, top:top}} />
                </TableCell>)
            key++;
        });

     
        setCalendar(rows);
        props.pageLoader(false);
    }

    return (
        <Box component={'div'}>
            <Box component={'div'} className="fc fc-media-screen fc-direction-ltr fc-theme-standard" >
                <Box component={'div'} className="fc-header-toolbar fc-toolbar">
                    <Grid container>
                        <Grid item xl={4} className="fc-toolbar-chunk">
                            <Box component={'h2'} className="fc-toolbar-title">{dateToString(weeks[0], 'it', true) + ' - ' + dateToString(weeks[6], 'it', true)}</Box>
                        </Grid>
                        <Grid item xl={4} className="fc-toolbar-chunk" />
                        <Grid item xl={3} className="fc-toolbar-chunk" >
                            <Button className="btn btn-info" onClick={today}>Oggi</Button>
                            <Box component={'div'} className="fc-button-group">
                                <Button className="btn btn-info fc-button" onClick={preview}><ArrowBackIosNewIcon /></Button>
                                <Button className="btn btn-info fc-button" onClick={next}><ArrowForwardIosIcon /></Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                
                <Box component={'div'} className="text-center m-4"><Button className={buttonClass} onClick={setWorkSession}>{buttonText}</Button></Box>
                <Box component={'div'} className="fc-view-harness fc-view-harness-active">
                    <Box component={'div'} className="fc-dayGridMonth-view fc-view fc-daygrid">
                        <Grid container>
                            <Grid item xs={(colEvents > 0) ? 0 : 12} md={colCalendar} sx={{ height: '550px', overflow: 'hidden', display: (window.innerWidth <= 870) ? ((displayEvents == 'none') ? 'block' : 'none') : 'block' }}>
                                <Table className="table align-items-center table-opacity-mobile">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="text-center" key={'hours'}></TableCell>
                                            <TableCell className="text-center" key={'lun'}>Lun {new Date(weeks[0]).getDate()}</TableCell>
                                            <TableCell className="text-center" key={'mar'}>Mar {new Date(weeks[1]).getDate()}</TableCell>
                                            <TableCell className="text-center" key={'mer'}>Mer {new Date(weeks[2]).getDate()}</TableCell>
                                            <TableCell className="text-center" key={'gio'}>Gio {new Date(weeks[3]).getDate()}</TableCell>
                                            <TableCell className="text-center" key={'ven'}>Ven {new Date(weeks[4]).getDate()}</TableCell>
                                            <TableCell className="text-center" key={'sab'}>Sab {new Date(weeks[5]).getDate()}</TableCell>
                                            <TableCell className="text-center" key={'dom'}>Dom {new Date(weeks[6]).getDate()}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>{calendar}</TableRow>
                                    </TableBody>
                                </Table>
                                <Grid item xs={12} className="mt-4 pt-4">
                                    <Box component={'h3'} className="text-center">Le Timbrature del Giorno</Box>
                                    <Box component={'ul'} className="text-center">
                                        {todaySessions.map((item) => {
                                            return <Box>{item}</Box>;
                                        })}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item xs={(colEvents > 0) ? 12 : 0} md={colEvents} sx={{ display: displayEvents, zIndex: 2, height: '500px', overflowY: "scroll" }} >
                                <Box component={'div'} className="h-100 event-handler">
                                    <Grid container>
                                        <Grid item xs={11}></Grid>
                                        <Grid item xs={1} sx={{ paddingLeft: '10px' }} ><ClearIcon className="cursor-pointer p-0" onClick={closeEvent} /></Grid>
                                        <Grid item xs={12}>{eventCard}</Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}


export default WorkSession;